import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { colors, typography } from '../styles/SharedStyles';
import { GradientButton } from '../components/GradientButton';
import styled from 'styled-components';
import { isDevelopment } from '../utils/environment';

// Admin Components
import { ProductManager } from '../components/admin/ProductManager';
import { PackageBuilder } from '../components/admin/PackageBuilder';
import { AnnouncementManager } from '../components/admin/AnnouncementManager';
import { MessageManager } from '../components/admin/MessageManager';
import { SalesAnalytics } from '../components/admin/SalesAnalytics';
import { SiteManagement } from '../components/admin/SiteManagement';
import { FulfillmentManager } from '../components/admin/FulfillmentManager';
import { SystemBuilder } from '../components/admin/SystemBuilder';
import { SupportManager } from '../components/admin/SupportManager';
import { BlogManager } from '../components/admin/BlogManager';

// Password protection component
const PasswordProtection = ({ onAuthenticated }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [attempts, setAttempts] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === '$c911pDOODA') {
      // Store authentication in session storage
      sessionStorage.setItem('adminAuthenticated', 'true');
      onAuthenticated();
    } else {
      setAttempts(attempts + 1);
      setError(`Invalid password. ${3 - attempts} attempts remaining.`);
      if (attempts >= 2) {
        // After 3 failed attempts, redirect to home
        window.location.href = '/';
      }
    }
  };

  return (
    <View style={styles.authContainer}>
      <View style={styles.authCard}>
        <Text style={styles.authTitle}>Admin Access</Text>
        <Text style={styles.authDescription}>Please enter the admin password to continue.</Text>
        
        <form onSubmit={handleSubmit} style={styles.authForm}>
          <PasswordInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
          
          {error && <Text style={styles.errorText}>{error}</Text>}
          
          <SubmitButton type="submit">
            Login
          </SubmitButton>
        </form>
      </View>
    </View>
  );
};

const NavGroup = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const NavDivider = styled.div`
  width: 1px;
  height: 30px;
  background: ${colors.gray};
  opacity: 0.3;
  margin: 0 10px;
`;

const PasswordInput = styled.input`
  width: 100%;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
  margin-bottom: 15px;
  
  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
    box-shadow: 0 0 0 2px rgba(231, 28, 36, 0.1);
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(to bottom, ${colors.primary.start}, ${colors.primary.end});
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(231, 28, 36, 0.2);
  }
  
  &:active {
    transform: translateY(1px);
  }
`;

const AdminNav = ({ navigate }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Update page title based on current path
  useEffect(() => {
    let title = 'Admin Dashboard | Security Central Protection';
    if (currentPath.includes('/sales')) {
      title = 'Admin - Sales Analytics';
    } else if (currentPath.includes('/fulfillment')) {
      title = 'Admin - Order Fulfillment';
    } else if (currentPath.includes('/products')) {
      title = 'Admin - Products';
    } else if (currentPath.includes('/packages')) {
      title = 'Admin - Package Builder';
    } else if (currentPath.includes('/system-builder')) {
      title = 'Admin - System Builder';
    } else if (currentPath.includes('/announcements')) {
      title = 'Admin - Announcements';
    } else if (currentPath.includes('/messages')) {
      title = 'Admin - Messages';
    } else if (currentPath.includes('/site-management')) {
      title = 'Admin - Site Content';
    } else if (currentPath.includes('/blog')) {
      title = 'Admin - Blog';
    }
    document.title = title;
  }, [currentPath]);

  return (
    <View style={styles.adminNav}>
      <Text style={styles.adminTitle}>Admin Dashboard</Text>
      <View style={styles.navLinks}>
        {/* Sales & Orders Group */}
        <NavGroup>
          <GradientButton
            onPress={() => navigate('/admin/sales')}
            text="Sales Analytics"
            variant={currentPath.includes('/sales') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/sales') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/fulfillment')}
            text="Order Fulfillment"
            variant={currentPath.includes('/fulfillment') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/fulfillment') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
        </NavGroup>

        <NavDivider />

        {/* Products & Systems Group */}
        <NavGroup>
          <GradientButton
            onPress={() => navigate('/admin/products')}
            text="Products"
            variant={currentPath.includes('/products') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/products') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/packages')}
            text="Package Builder"
            variant={currentPath.includes('/packages') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/packages') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/system-builder')}
            text="System Builder"
            variant={currentPath.includes('/system-builder') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/system-builder') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
        </NavGroup>

        <NavDivider />

        {/* Content Management Group */}
        <NavGroup>
          <GradientButton
            onPress={() => navigate('/admin/announcements')}
            text="Announcements"
            variant={currentPath.includes('/announcements') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/announcements') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/messages')}
            text="Messages"
            variant={currentPath.includes('/messages') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/messages') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/site-content')}
            text="Site Content"
            variant={currentPath.includes('/site-content') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/site-content') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/site-management')}
            text="Site Management"
            variant={currentPath.includes('/site-management') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/site-management') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/support')}
            text="Support"
            variant={currentPath.includes('/support') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/support') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
          <GradientButton
            onPress={() => navigate('/admin/blog')}
            text="Blog"
            variant={currentPath.includes('/blog') ? 'primary' : 'secondary'}
            style={styles.navButton}
            textStyle={currentPath.includes('/blog') ? { ...styles.navButtonText, fontSize: 14 } : styles.navButtonText}
          />
        </NavGroup>
      </View>
    </View>
  );
};

const Welcome = () => (
  <View style={styles.welcome}>
    <Text style={styles.welcomeTitle}>Welcome to Admin Dashboard</Text>
    <Text style={styles.welcomeText}>
      Select a section from the navigation menu to get started.
    </Text>
  </View>
);

const SITE_MANAGEMENT_PASSWORD = 'Newtjhawk27';

const SiteManagementTab = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [stripeMode, setStripeMode] = useState('test');
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchStripeMode = async () => {
      const mode = await getStripeMode();
      setStripeMode(mode);
    };
    fetchStripeMode();
  }, []);

  const handleStripeToggle = async () => {
    setIsUpdating(true);
    try {
      const newMode = stripeMode === 'test' ? 'live' : 'test';
      const success = await updateStripeMode(newMode);
      if (success) {
        setStripeMode(newMode);
        alert(`Successfully switched to ${newMode.toUpperCase()} mode`);
      } else {
        throw new Error('Failed to update Stripe mode');
      }
    } catch (error) {
      alert('Error updating Stripe mode: ' + error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === SITE_MANAGEMENT_PASSWORD) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Incorrect password');
    }
  };

  if (!isAuthenticated) {
    return (
      <div style={{ maxWidth: '400px', margin: '40px auto', padding: '20px' }}>
        <h2 style={{ marginBottom: '20px' }}>Site Management Access</h2>
        <form onSubmit={handlePasswordSubmit}>
          <div style={{ marginBottom: '20px' }}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '4px',
                border: '1px solid #ccc'
              }}
            />
          </div>
          {error && (
            <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>
          )}
          <button
            type="submit"
            style={{
              background: '#e71c24',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Access Site Management
          </button>
        </form>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ marginBottom: '30px' }}>Site Management</h2>
      
      <div style={{ 
        background: 'white', 
        padding: '20px', 
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        marginBottom: '20px'
      }}>
        <h3 style={{ marginBottom: '20px' }}>Stripe Payment Mode</h3>
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <div>Current Mode: <strong>{stripeMode.toUpperCase()}</strong></div>
          <button
            onClick={handleStripeToggle}
            disabled={isUpdating}
            style={{
              background: stripeMode === 'live' ? '#28a745' : '#e71c24',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '4px',
              cursor: isUpdating ? 'not-allowed' : 'pointer',
              opacity: isUpdating ? 0.7 : 1
            }}
          >
            {isUpdating ? 'Updating...' : `Switch to ${stripeMode === 'test' ? 'LIVE' : 'TEST'} Mode`}
          </button>
        </div>
      </div>

      {/* Add more site management features here */}
    </div>
  );
};

export const AdminPage = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(isDevelopment());
  
  // Check if user is already authenticated
  useEffect(() => {
    if (!isDevelopment()) {
      const authenticated = sessionStorage.getItem('adminAuthenticated') === 'true';
      setIsAuthenticated(authenticated);
    }
  }, []);
  
  // Logout function
  const handleLogout = () => {
    if (!isDevelopment()) {
      sessionStorage.removeItem('adminAuthenticated');
      setIsAuthenticated(false);
    }
  };

  if (!isAuthenticated && !isDevelopment()) {
    return (
      <View style={styles.pageContainer}>
        <HeaderBar />
        <ScrollView style={styles.content}>
          <View style={styles.mainContent}>
            <PasswordProtection onAuthenticated={() => setIsAuthenticated(true)} />
          </View>
        </ScrollView>
        <Footer />
      </View>
    );
  }

  return (
    <View style={styles.pageContainer}>
      <HeaderBar />
      <ScrollView style={styles.content}>
        <View style={styles.mainContent}>
          <View style={styles.logoutContainer}>
            {!isDevelopment() && (
              <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            )}
          </View>
          <AdminNav navigate={navigate} />
          <View style={styles.adminContent}>
            <Routes>
              <Route index element={<Welcome />} />
              <Route path="sales" element={<SalesAnalytics />} />
              <Route path="fulfillment" element={<FulfillmentManager />} />
              <Route path="products" element={<ProductManager />} />
              <Route path="packages" element={<PackageBuilder />} />
              <Route path="system-builder" element={<SystemBuilder />} />
              <Route path="announcements" element={<AnnouncementManager />} />
              <Route path="messages" element={<MessageManager />} />
              <Route path="site-content" element={<SiteManagement />} />
              <Route path="site-management" element={<SiteManagementTab />} />
              <Route path="support" element={<SupportManager />} />
              <Route path="blog" element={<BlogManager />} />
            </Routes>
          </View>
        </View>
      </ScrollView>
      <Footer />
    </View>
  );
};

const LogoutButton = styled.button`
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #eee;
    color: ${colors.primary.solid};
  }
`;

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    minHeight: '100vh',
    width: '100%',
    backgroundColor: 'transparent',
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 100,
    paddingBottom: 40,
    minWidth: 1200,
    width: '100%',
    alignSelf: 'center',
  },
  logoutContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  adminNav: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  adminTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  navLinks: {
    flexDirection: 'row',
    gap: 15,
    flexWrap: 'wrap',
  },
  navButton: {
    minWidth: 150,
    height: 40,
    paddingVertical: 8,
  },
  navButtonText: {
    fontSize: 14,
    fontWeight: '600',
  },
  adminContent: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: 30,
    borderRadius: 10,
    minHeight: 500,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  welcome: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  welcomeTitle: {
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  welcomeText: {
    fontSize: 18,
    color: colors.gray,
    textAlign: 'center',
  },
  authContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  authCard: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 30,
    width: '100%',
    maxWidth: 400,
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  authTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.primary,
    textAlign: 'center',
  },
  authDescription: {
    fontSize: 16,
    color: '#666',
    marginBottom: 20,
    textAlign: 'center',
  },
  authForm: {
    width: '100%',
  },
  errorText: {
    color: 'red',
    marginBottom: 15,
    fontSize: 14,
    textAlign: 'center',
  },
  comingSoonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
  },
  comingSoonTitle: {
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary,
  },
  comingSoonText: {
    fontSize: 18,
    color: colors.gray,
  },
}); 