import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { colors } from '../styles/SharedStyles';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { functions } from '../config/firebase';
import { db } from '../config/firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import { US_STATES } from '../utils/constants';
import { STRIPE_CONFIG, getStripeMode, getApiBaseUrl } from '../config/stripe';
import {
  getDistanceFromCompany,
  calculateShippingFee,
  calculateInstallationFee,
  COMPANY_ADDRESS
} from '../utils/distanceCalculator';
import { GradientButton } from '../components/GradientButton';
import { usePageTitle } from '../hooks/usePageTitle';
import { DisclaimerMessage } from '../components/DisclaimerMessage';
import { ProductRecommendation } from '../components/ProductRecommendation';

// Initialize Stripe with the environment-specific key
const stripePromise = loadStripe(STRIPE_CONFIG.publishableKey);

// Development pre-fill data
const DEV_PREFILL = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zipCode: ''
};

// Add store location constant
const STORE_LOCATION = {
  address: '12821 Capital Street',
  city: 'Oak Park',
  state: 'MI',
  zipCode: '48237',
  coordinates: {
    lat: 42.4720,
    lng: -83.1826
  }
};

// Update the API_BASE_URL definition
const API_BASE_URL = getApiBaseUrl();

// Update the getLatestProductData function
const getLatestProductData = async (productId) => {
  try {
    // First try to get from products collection
    const productDoc = await getDoc(doc(db, 'products', productId));
    if (productDoc.exists()) {
      return productDoc.data();
    }

    // If not found in products, try to find in systemBuilder components
    const systemTypes = ['alarms', 'cameras', 'halo', 'poe'];

    for (const type of systemTypes) {
      const componentsRef = collection(db, 'systemBuilder', type, 'components');
      const querySnapshot = await getDocs(componentsRef);

      // Search through all documents in the subcollection
      const matchingDoc = querySnapshot.docs.find(doc => {
        const data = doc.data();
        return data.productId === productId || // Match by productId
          data.id === productId || // Match by component id
          data.name === productId || // Exact name match
          data.name.replace(/[^\w\s]/g, '') === productId.replace(/[^\w\s]/g, ''); // Normalized name match
      });

      if (matchingDoc) {
        console.log(`Found component ${productId} in systemBuilder/${type}:`, matchingDoc.data());
        return matchingDoc.data();
      }
    }

    console.warn(`Product ${productId} not found in any collection`);
    return null;
  } catch (error) {
    console.error(`Error fetching product ${productId}:`, error);
    return null;
  }
};

// Add function to process package products
const getPackageProductsData = async (products) => {
  try {
    const updatedProducts = await Promise.all(
      products.map(async (product) => {
        const latestData = await getLatestProductData(product.id);
        return {
          ...product,
          installationFee: latestData?.installationFee || 0
        };
      })
    );
    return updatedProducts;
  } catch (error) {
    console.error('Error processing package products:', error);
    return products;
  }
};

// Update the calculateInstallationFees function to only calculate base fee
const calculateInstallationFees = async (items) => {
  let baseInstallationFee = 0;
  console.log('\n=== Starting Installation Fee Calculation ===');
  console.log('Items to process:', JSON.stringify(items, null, 2));

  // Calculate base installation fees from products
  for (const item of items) {
    console.log('\n--- Processing Item ---');
    console.log('Item Name:', item.name);
    console.log('Item Type:', item.type);

    if (item.type === 'system') {
      console.log('\nProcessing System Components:');

      // Process model (HALO)
      if (item.components?.model) {
        try {
          const latestData = await getLatestProductData(item.components.model.name);
          const fee = parseFloat(latestData?.installationFee || 0);
          console.log(`HALO Model: ${item.components.model.name}, Installation Fee: $${fee}`);
          baseInstallationFee += fee;
        } catch (error) {
          console.error('Error processing HALO model:', error);
        }
      }

      // Process power (POE)
      if (item.components?.power) {
        try {
          const latestData = await getLatestProductData(item.components.power.name);
          const fee = parseFloat(latestData?.installationFee || 0);
          console.log(`POE: ${item.components.power.name}, Installation Fee: $${fee}`);
          baseInstallationFee += fee;
        } catch (error) {
          console.error('Error processing POE:', error);
        }
      }

      // Process alarm components
      if (item.components?.alarms) {
        console.log('\nProcessing Alarm Components:');
        for (const alarm of item.components.alarms) {
          try {
            const latestData = await getLatestProductData(alarm.name);
            const fee = parseFloat(latestData?.installationFee || alarm.installationFee || 0);
            const qty = alarm.quantity || 1;
            const totalAlarmFee = fee * qty;
            console.log(`Alarm: ${alarm.name}, Fee: $${fee}, Qty: ${qty}, Total: $${totalAlarmFee}`);
            baseInstallationFee += totalAlarmFee;
          } catch (error) {
            console.error(`Error processing alarm ${alarm.name}:`, error);
          }
        }
      }

      // Process camera components
      if (item.components?.cameras) {
        console.log('\nProcessing Camera Components:');
        for (const camera of item.components.cameras) {
          try {
            const latestData = await getLatestProductData(camera.name);
            const fee = parseFloat(latestData?.installationFee || camera.installationFee || 0);
            const qty = camera.quantity || 1;
            const totalCameraFee = fee * qty;
            console.log(`Camera: ${camera.name}, Fee: $${fee}, Qty: ${qty}, Total: $${totalCameraFee}`);
            baseInstallationFee += totalCameraFee;
          } catch (error) {
            console.error(`Error processing camera ${camera.name}:`, error);
          }
        }
      }
    } else if (item.type === 'package') {
      console.log('\nProcessing Package:', item.name);
      if (item.products) {
        for (const product of item.products) {
          try {
            const latestData = await getLatestProductData(product.id);
            const fee = parseFloat(latestData?.installationFee || 0);
            const qty = product.packageQuantity || product.quantity || 1;
            const totalProductFee = fee * qty;
            console.log(`Package Product: ${product.name}, Fee: $${fee}, Qty: ${qty}, Total: $${totalProductFee}`);
            baseInstallationFee += totalProductFee;
          } catch (error) {
            console.error(`Error processing package product ${product.name}:`, error);
          }
        }
      }
    } else {
      // Handle individual products
      console.log('\nProcessing Individual Product:', item.name);
      try {
        const latestData = await getLatestProductData(item.id);
        const fee = parseFloat(latestData?.installationFee || item.installationFee || 0);
        const qty = item.quantity || 1;
        const totalProductFee = fee * qty;
        console.log(`Product: ${item.name}, Fee: $${fee}, Qty: ${qty}, Total: $${totalProductFee}`);
        baseInstallationFee += totalProductFee;
      } catch (error) {
        console.error(`Error processing individual product ${item.name}:`, error);
      }
    }
  }

  console.log('\n=== Installation Fee Summary ===');
  console.log('Total Base Installation Fee:', baseInstallationFee);
  return baseInstallationFee;
};

// Update the Toast styled component
const Toast = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.type === 'error' ? '#fee' : '#e8f5e9'};
  color: ${props => props.type === 'error' ? '#c00' : '#2e7d32'};
  padding: 15px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: ${props => props.$isVisible ? 1 : 0};
  visibility: ${props => props.$isVisible ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
`;

export default function CheckoutPage() {
  usePageTitle('Checkout');
  const navigate = useNavigate();
  const { cartItems, getTotalPrice, updateQuantity, removeFromCart } = useCart();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(DEV_PREFILL);
  const [deliveryMethod, setDeliveryMethod] = useState('shipping');
  const [shippingCost, setShippingCost] = useState(0);
  const [calculatingShipping, setCalculatingShipping] = useState(false);
  const [distance, setDistance] = useState(null);
  const [calculatingDistance, setCalculatingDistance] = useState(false);
  const [shippingFee, setShippingFee] = useState(0);
  const [installationFee, setInstallationFee] = useState(0);
  const [calculatingFees, setCalculatingFees] = useState(false);
  const [shippingError, setShippingError] = useState(null);
  const [toast, setToast] = useState({ show: false, message: '', type: 'error' });

  // Add state for monitoring packages
  const [selectedMonitoring, setSelectedMonitoring] = useState({
    alarm: null,
    video: null,
    alarmWarranty: false,
    videoWarranty: false,
    lte: false
  });

  // Update the monitoring packages options
  const monitoringPackages = {
    alarm: [
      {
        id: 'diy-alarm',
        name: 'DIY Monitoring',
        price: 14.95,
        description: 'Individual monitoring without office dispatch',
        restrictions: ['proseries']
      },
      {
        id: 'pro-alarm',
        name: 'Professional Alarm Monitoring',
        price: 24.95,
        description: 'Professional monitoring with cellular options for PROSERIES Alarm',
        restrictions: ['proseries']
      }
    ],
    video: [
      {
        id: 'diy-video',
        name: 'DIY Video Monitoring',
        price: 19.95,
        description: 'Storage included, no fees for footage access',
        restrictions: []
      },
      {
        id: 'pro-video',
        name: 'Professional Video Monitoring',
        price: 75.00,
        description: 'Professional monitoring per camera',
        perCamera: true,
        restrictions: []
      }
    ]
  };

  // Update the getCameraCount function to include standalone cameras and package cameras
  const getCameraCount = () => {
    return cartItems.reduce((count, item) => {
      // Count cameras in systems
      if (item.type === 'system' && item.components?.cameras) {
        return count + item.components.cameras.reduce((sum, camera) => sum + (camera.quantity || 1), 0);
      }
      // Count standalone cameras
      if (item.name?.toLowerCase().includes('camera') || item.name?.toLowerCase().includes('hikvision')) {
        return count + (item.quantity || 1);
      }
      // Count cameras in packages
      if (item.type === 'package' && item.products) {
        return count + item.products.reduce((sum, product) => {
          if (product.name?.toLowerCase().includes('camera') || product.name?.toLowerCase().includes('hikvision')) {
            return sum + (product.quantity || product.packageQuantity || 1);
          }
          return sum;
        }, 0);
      }
      return count;
    }, 0);
  };

  // Add new function to count total components by category
  const getTotalComponentsByCategory = () => {
    let counts = {
      cameras: 0,
      nvr: 0,
      halo: 0,
      deterrence: 0
    };

    cartItems.forEach(item => {
      if (item.type === 'system') {
        // Count cameras in system
        if (item.components?.cameras) {
          counts.cameras += item.components.cameras.reduce((sum, camera) => sum + (camera.quantity || 1), 0);
        }
        // Count Virtual Guard
        if (item.components?.model) {
          counts.halo += 1;
        }
        // Count NVR
        if (item.components?.nvr) {
          counts.nvr += 1;
        }
        // Count deterrence devices
        if (item.components?.deterrence) {
          counts.deterrence += item.components.deterrence.reduce((sum, device) => sum + (device.quantity || 1), 0);
        }
      } else if (item.type === 'package' && item.products) {
        // Count components in packages
        item.products.forEach(product => {
          if (product.name?.toLowerCase().includes('camera') || product.name?.toLowerCase().includes('hikvision')) {
            counts.cameras += (product.quantity || product.packageQuantity || 1);
          }
          if (product.name?.toLowerCase().includes('nvr')) {
            counts.nvr += (product.quantity || product.packageQuantity || 1);
          }
          if (product.name?.toLowerCase().includes('halo')) {
            counts.halo += (product.quantity || product.packageQuantity || 1);
          }
          if (product.name?.toLowerCase().includes('deterrence')) {
            counts.deterrence += (product.quantity || product.packageQuantity || 1);
          }
        });
      } else {
        // Count standalone products
        if (item.name?.toLowerCase().includes('camera') || item.name?.toLowerCase().includes('hikvision')) {
          counts.cameras += (item.quantity || 1);
        }
        if (item.name?.toLowerCase().includes('nvr')) {
          counts.nvr += (item.quantity || 1);
        }
        if (item.name?.toLowerCase().includes('halo')) {
          counts.halo += (item.quantity || 1);
        }
        if (item.name?.toLowerCase().includes('deterrence')) {
          counts.deterrence += (item.quantity || 1);
        }
      }
    });

    return counts;
  };

  // Update the hasProSeriesItems function to be more comprehensive
  const hasProSeriesItems = () => {
    return cartItems.some(item => {
      // Check regular items
      if (item.name?.toLowerCase().includes('proseries')) {
        return true;
      }
      // Check system components
      if (item.type === 'system') {
        // Check model name
        if (item.components?.model?.name?.toLowerCase().includes('proseries')) {
          return true;
        }
        // Check alarm components
        if (item.components?.alarms?.some(alarm =>
          alarm.name?.toLowerCase().includes('proseries')
        )) {
          return true;
        }
      }
      // Check package items
      if (item.type === 'package' && item.products) {
        return item.products.some(product =>
          product.name?.toLowerCase().includes('proseries')
        );
      }
      return false;
    });
  };

  useEffect(() => {
    if (cartItems.length === 0) {
      navigate('/products');
    }
  }, [cartItems]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (field, value) => {
    setCustomerInfo(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAutoFill = () => {
    if ("geolocation" in navigator) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(async (position) => {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`,
            {
              headers: {
                'User-Agent': 'SCP911-Website/1.0'
              }
            }
          );
          const data = await response.json();

          if (data.address) {
            // Find the matching state abbreviation
            const stateMatch = US_STATES.find(state =>
              state.name.toLowerCase() === (data.address.state || '').toLowerCase() ||
              state.abbreviation.toLowerCase() === (data.address.state_code || '').toLowerCase()
            );

            setCustomerInfo(prev => ({
              ...prev,
              address: `${data.address.house_number || ''} ${data.address.road || ''}`.trim(),
              city: data.address.city || data.address.town || data.address.village || '',
              state: stateMatch ? stateMatch.abbreviation : '',
              zipCode: data.address.postcode || ''
            }));
          }
        } catch (error) {
          console.error('Error fetching address:', error);
          setError('Failed to auto-fill address. Please enter manually.');
        } finally {
          setLoading(false);
        }
      }, (error) => {
        console.error('Geolocation error:', error);
        setError('Location access denied. Please enter address manually.');
        setLoading(false);
      });
    } else {
      setError('Geolocation is not supported by your browser.');
    }
  };

  // Phone number formatting
  const formatPhoneNumber = (value) => {
    const phone = value.replace(/\D/g, '');
    if (phone.length < 4) return phone;
    if (phone.length < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    if (formattedPhone.length <= 14) {
      handleInputChange('phone', formattedPhone);
    }
  };

  // Calculate shipping cost based on distance
  const calculateShippingCost = async (zipCode) => {
    try {
      setCalculatingShipping(true);
      const distance = await getDistanceFromCompany(zipCode);

      // Calculate shipping fee (free first 50 miles, then $1/mile)
      const shippingMiles = Math.max(0, distance - 50);
      const newShippingFee = shippingMiles * 1;
      setShippingCost(newShippingFee);
      setDistance(distance);
    } catch (error) {
      console.error('Error calculating shipping:', error);
      setShippingCost(25); // Default fallback rate
    } finally {
      setCalculatingShipping(false);
    }
  };

  // Get total with shipping
  const getTotalWithShipping = () => {
    const subtotal = getTotalPrice();
    return deliveryMethod === 'shipping' ? subtotal + shippingCost : subtotal;
  };

  // Update the useEffect for delivery method changes
  useEffect(() => {
    if (deliveryMethod === 'shipping') {
      // Reset installation-related fees when switching to shipping
      setInstallationFee(0);
      setShippingFee(0);
      setDistance(null);
    }
  }, [deliveryMethod]);

  // Update the isCheckoutDisabled function
  const isCheckoutDisabled = () => {
    if (loading) return { disabled: true, reason: 'Processing your request...' };

    // Basic validation for all delivery methods
    const requiredFields = ['firstName', 'lastName', 'email'];

    // Additional fields for shipping and installation
    if (deliveryMethod !== 'pickup') {
      requiredFields.push('address', 'city', 'state', 'zipCode');
    }

    // Check for missing required fields
    const missingFields = requiredFields.filter(field => !customerInfo[field]);
    if (missingFields.length > 0) {
      return {
        disabled: true,
        reason: `Please fill in all required fields: ${missingFields.map(field => field.charAt(0).toUpperCase() + field.slice(1)).join(', ')}`
      };
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(customerInfo.email)) {
      return { disabled: true, reason: 'Please enter a valid email address' };
    }

    // For shipping or installation, ensure shipping has been calculated
    if ((deliveryMethod === 'shipping' || deliveryMethod === 'installation') && distance === null) {
      return { disabled: true, reason: 'Please calculate shipping/installation fees first' };
    }

    // Check if fees are still being calculated
    if (calculatingFees) {
      return { disabled: true, reason: 'Calculating fees...' };
    }

    // If all validations pass, enable the button
    return { disabled: false, reason: '' };
  };

  // Update the handleCheckoutClick function to show the reason when disabled
  const handleCheckoutClick = () => {
    const checkoutState = isCheckoutDisabled();
    if (checkoutState.disabled) {
      showToast(checkoutState.reason, 'error');
      return;
    }
    handleCheckout();
  };

  // Add this function near the top with other state management
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Update the handleValidateAddress function
  const handleValidateAddress = async () => {
    // Validate required fields for address validation
    const requiredFields = ['address', 'city', 'state', 'zipCode'];
    const missingFields = requiredFields.filter(field => !customerInfo[field]);

    if (missingFields.length > 0) {
      showToast(`Please fill in all required fields: ${missingFields.map(field =>
        field.charAt(0).toUpperCase() + field.slice(1)).join(', ')}`, 'error');
      return;
    }

    if (!customerInfo.zipCode || customerInfo.zipCode.length !== 5) {
      showToast('Please enter a valid 5-digit ZIP code.', 'error');
      return;
    }

    setCalculatingFees(true);
    setShippingError(null);

    try {
      // Get distance based on ZIP code only
      const distanceResult = await getDistanceFromCompany(customerInfo.zipCode);

      // Debug alerts
      // alert(`Distance calculation:\nZIP Code: ${customerInfo.zipCode}\nDistance: ${distanceResult} miles`);
      // console.log('Distance calculation:', { zipCode: customerInfo.zipCode, distance: distanceResult });

      setDistance(distanceResult);

      if (deliveryMethod === 'installation' && distanceResult > 250) {
        setDeliveryMethod('shipping');
        scrollToTop();
        showToast('Your location is more than 250 miles away. We have automatically switched you to shipping method.', 'error');
        return;
      }

      if (deliveryMethod === 'installation') {
        // Calculate base installation fee (without distance)
        const baseFee = await calculateInstallationFees(cartItems);
        console.log('Base installation fee:', baseFee);

        // Calculate delivery fee separately
        let deliveryFee = 0;
        if (distanceResult > 50) {
          deliveryFee = Math.min(distanceResult - 50, 150);
          console.log('Distance fee:', deliveryFee);
          showToast(`Delivery fee: $${deliveryFee.toFixed(2)} (${distanceResult} miles, $1/mile after first 50 miles)`, 'success');
        } else {
          showToast(`Delivery is free! (${distanceResult} miles - within 50 mile radius)`, 'success');
        }

        // Set the installation fee as the sum of base fee and delivery fee
        setInstallationFee(baseFee);
        setShippingFee(deliveryFee);
      } else {
        showToast('Address validated successfully!', 'success');
      }
    } catch (error) {
      console.error('Error validating address:', error);
      setShippingError('Unable to validate address. Please verify your address and try again.');
    } finally {
      setCalculatingFees(false);
    }
  };

  // Update the DeliveryOption component to scroll to top on selection
  const handleDeliveryMethodChange = (method) => {
    setDeliveryMethod(method);
    scrollToTop();
  };

  // Update the CalculateButton to show validation state
  const CalculateButton = styled.button`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    padding: 12px 20px;
    height: auto;
    opacity: ${props => props.disabled ? 0.5 : 1};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.2s ease;
    font-size: 16px;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 8px;
    background: ${props => props.disabled ? '#ccc' : `linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%)`};
    position: relative;

    &:hover {
      transform: ${props => props.disabled ? 'none' : 'scale(1.02)'};
      box-shadow: ${props => props.disabled ? 'none' : '0 4px 12px rgba(231, 28, 36, 0.2)'};
    }

    &:disabled {
      background: #ccc;
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }

    &:after {
      content: '${props => props.required ? '* Required before proceeding' : ''}';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      color: ${colors.primary.solid};
      white-space: nowrap;
      opacity: 0.8;
    }
  `;

  // Update the calculateShipping function
  const calculateShipping = async () => {
    if (!customerInfo.zipCode || customerInfo.zipCode.length !== 5) {
      showToast('Please enter a valid 5-digit ZIP code.');
      return;
    }

    setCalculatingFees(true);
    setShippingError(null);

    try {
      const newDistance = await getDistanceFromCompany(customerInfo.zipCode);
      setDistance(newDistance);

      // Calculate shipping fee (free first 50 miles, then $1/mile)
      const shippingMiles = Math.max(0, newDistance - 50);
      const newShippingFee = shippingMiles * 1;
      setShippingFee(newShippingFee);
    } catch (error) {
      console.error('Error calculating shipping:', error);
      setShippingError('Unable to calculate shipping. Please verify your ZIP code and try again.');
    } finally {
      setCalculatingFees(false);
    }
  };

  // Update the ZIP code input to prevent the Chrome extension error
  const handleZipCodeChange = (e) => {
    e.preventDefault(); // Prevent default behavior
    const zip = e.target.value.replace(/\D/g, '');
    if (zip.length <= 5) {
      handleInputChange('zipCode', zip);
      setShippingError(null); // Clear any previous errors when user starts typing
    }
  };

  // Update getTotalWithFees to handle fees correctly
  const getTotalWithFees = () => {
    let total = getTotalPrice();

    // Add installation and delivery fees if applicable
    if (deliveryMethod === 'installation') {
      total += installationFee;    // Base installation fee
      total += shippingFee;        // Distance-based delivery fee
    }

    // Add monitoring fees
    if (selectedMonitoring.alarm) {
      total += selectedMonitoring.alarm.price;
      if (selectedMonitoring.alarmWarranty) {
        total += 10.00;
      }
      if (selectedMonitoring.lte) {
        total += 10.00;
      }
    }

    if (selectedMonitoring.video) {
      const cameraCount = getCameraCount();
      const videoMonitoringFee = selectedMonitoring.video.perCamera
        ? selectedMonitoring.video.price * cameraCount
        : selectedMonitoring.video.price;
      total += videoMonitoringFee;

      if (selectedMonitoring.videoWarranty) {
        total += 10.00;
      }
    }

    return total;
  };

  // Update the handleMonitoringChange function
  const handleMonitoringChange = (type, pkg) => {
    // Validate alarm monitoring selection only
    if (type === 'alarm') {
      const hasProseries = cartItems.some(item => {
        // Check regular items
        if (item.name?.toLowerCase().includes('proseries')) {
          return true;
        }
        // Check system components
        if (item.type === 'system') {
          // Check model name
          if (item.components?.model?.name?.toLowerCase().includes('proseries')) {
            return true;
          }
          // Check alarm components
          if (item.components?.alarms?.some(alarm =>
            alarm.name?.toLowerCase().includes('proseries')
          )) {
            return true;
          }
        }
        // Check package items
        if (item.type === 'package' && item.products) {
          return item.products.some(product =>
            product.name?.toLowerCase().includes('proseries')
          );
        }
        return false;
      });

      if (!hasProseries) {
        showToast('Alarm monitoring is only available for PROSERIES alarms at this time.', 'error');
        return;
      }
    }

    setSelectedMonitoring(prev => ({
      ...prev,
      [type]: prev[type]?.id === pkg.id ? null : pkg
    }));
  };

  // Update the handleAddonChange function to prevent deselection of monitoring
  const handleAddonChange = (addon) => {
    // Validate that parent monitoring is selected before allowing addon changes
    if (addon === 'alarmWarranty' || addon === 'lte') {
      if (!selectedMonitoring.alarm) {
        showToast('You must select an alarm monitoring plan first.', 'error');
        return;
      }
    }
    if (addon === 'videoWarranty') {
      if (!selectedMonitoring.video) {
        showToast('You must select a video monitoring plan first.', 'error');
        return;
      }
    }

    setSelectedMonitoring(prev => ({
      ...prev,
      [addon]: !prev[addon]
    }));
  };

  // Add showToast function
  const showToast = (message, type = 'error') => {
    setToast({ show: true, message, type });
    setTimeout(() => {
      setToast({ show: false, message: '', type });
    }, 3000);
  };

  const handleCheckout = async () => {
    // Validate shipping/installation calculation
    if ((deliveryMethod === 'shipping' || deliveryMethod === 'installation') && distance === null) {
      showToast('Please validate your address before proceeding.');
      return;
    }

    // Validate required fields based on delivery method
    let requiredFields = ['firstName', 'lastName', 'email'];

    if (deliveryMethod !== 'pickup') {
      requiredFields = [...requiredFields, 'address', 'city', 'state', 'zipCode'];
    }

    const missingFields = requiredFields.filter(field => !customerInfo[field]);

    if (missingFields.length > 0) {
      showToast(`Please fill in all required fields: ${missingFields.join(', ')}`);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Get total components
      const componentCounts = getTotalComponentsByCategory();
      const totalComponents = componentCounts.cameras + componentCounts.nvr + componentCounts.halo;

      // Get recommendations that match the total component count
      const recommendationsDoc = await getDoc(doc(db, 'siteSettings', 'recommendations'));
      if (recommendationsDoc.exists()) {
        const { recommendations } = recommendationsDoc.data();
        const matchingRecommendation = recommendations.find(rec => 
          totalComponents >= rec.conditions.minTotalComponents
        );

        if (matchingRecommendation) {
          // Add recommendation products to allItems
          const recommendedProducts = await Promise.all(
            matchingRecommendation.products
              .filter(productId => productId) // Filter out empty strings
              .map(async productId => {
                const productDoc = await getDoc(doc(db, 'products', productId));
                if (productDoc.exists()) {
                  const productData = productDoc.data();
                  return {
                    id: productId,
                    name: productData.name,
                    price: productData.price,
                    imageUrl: productData.imageUrl,
                    quantity: 1,
                    type: 'recommendation'
                  };
                }
                return null;
              })
          );

          // Add valid recommended products to allItems
          allItems.push(...recommendedProducts.filter(p => p !== null));
        }
      }

      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to initialize');

      const requestUrl = `${API_BASE_URL}/createCheckoutSession`;
      console.log('Making request to:', requestUrl);

      // Create an array of all items including monitoring services
      const allItems = [...cartItems];

      // Add installation service as a line item if applicable
      if (deliveryMethod === 'installation') {
        // Add base installation fee as a line item
        allItems.push({
          id: 'installation-service',
          name: 'Professional Installation Service',
          price: installationFee,
          quantity: 1,
          type: 'installation'
        });

        // Add delivery fee as a separate line item if applicable
        if (shippingFee > 0) {
          allItems.push({
            id: 'delivery-fee',
            name: `Distance Fee (${distance} miles)`,
            price: shippingFee,
            quantity: 1,
            type: 'delivery'
          });
        }
      }

      // Add monitoring services as line items
      if (selectedMonitoring.alarm) {
        allItems.push({
          id: 'alarm-monitoring',
          name: `${selectedMonitoring.alarm.name} (Monthly)`,
          price: selectedMonitoring.alarm.price,
          quantity: 1,
          type: 'monitoring'
        });
      }

      if (selectedMonitoring.alarmWarranty) {
        allItems.push({
          id: 'alarm-warranty',
          name: 'Alarm Warranty (Monthly)',
          price: 10.00,
          quantity: 1,
          type: 'monitoring'
        });
      }

      if (selectedMonitoring.lte) {
        allItems.push({
          id: 'lte-backup',
          name: 'LTE Backup (Monthly)',
          price: 10.00,
          quantity: 1,
          type: 'monitoring'
        });
      }

      if (selectedMonitoring.video) {
        const cameraCount = getCameraCount();
        const videoPrice = selectedMonitoring.video.perCamera ?
          selectedMonitoring.video.price * cameraCount :
          selectedMonitoring.video.price;

        allItems.push({
          id: 'video-monitoring',
          name: `${selectedMonitoring.video.name}${selectedMonitoring.video.perCamera ? ` (${cameraCount} cameras)` : ''} (Monthly)`,
          price: videoPrice,
          quantity: 1,
          type: 'monitoring'
        });
      }

      if (selectedMonitoring.videoWarranty) {
        allItems.push({
          id: 'video-warranty',
          name: 'Video Warranty (Monthly)',
          price: 10.00,
          quantity: 1,
          type: 'monitoring'
        });
      }

      // Prepare the request body
      const requestBody = {
        items: allItems,
        customerInfo,
        success_url: `${window.location.origin}/order-success`,
        cancel_url: `${window.location.origin}/checkout`,
        mode: getStripeMode(),
        deliveryMethod,
        installationService: false,  // Set to false so backend doesn't add its own fee
        totalAmount: getTotalWithFees(),  // Add total amount to ensure it matches frontend
        customInstallation: deliveryMethod === 'installation'  // New flag to indicate we're handling installation ourselves
      };

      // Call the checkout session endpoint
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Checkout error response:', {
          status: response.status,
          statusText: response.statusText,
          body: errorText
        });
        let errorMessage;
        try {
          const errorData = JSON.parse(errorText);
          errorMessage = errorData.error || `HTTP error! status: ${response.status}`;
        } catch (e) {
          errorMessage = `HTTP error! status: ${response.status}`;
        }
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log('Checkout session created:', data);

      if (!data.url) {
        throw new Error('No checkout URL received from server');
      }

      // Redirect to Stripe Checkout
      window.location.href = data.url;
    } catch (err) {
      console.error('Checkout error:', err);
      setError(err.message || 'Failed to create checkout session');
      showToast(err.message || 'Failed to create checkout session');
    } finally {
      setLoading(false);
    }
  };

  // Add the handleQuantityChange function
  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity < 1) return;
    updateQuantity(itemId, newQuantity);
  };

  return (
    <View style={styles.container}>
      <HeaderBar />
      <CheckoutContainer>
        <CheckoutContent>
          <CheckoutTitle>Checkout</CheckoutTitle>

          {error && (
            <ErrorMessage>
              {error}
              <CloseError onClick={() => setError(null)}>×</CloseError>
            </ErrorMessage>
          )}

          <DeliveryMethodSection>
            <SectionTitle>Select Delivery Method</SectionTitle>
            <DeliveryOptions>
              <DeliveryOption
                selected={deliveryMethod === 'shipping'}
                onClick={() => handleDeliveryMethodChange('shipping')}
              >
                <DeliveryOptionTitle>Ship to Address</DeliveryOptionTitle>
                <DeliveryOptionDescription>
                  Have your items shipped directly to your location
                </DeliveryOptionDescription>
              </DeliveryOption>

              <DeliveryOption
                selected={deliveryMethod === 'pickup'}
                onClick={() => handleDeliveryMethodChange('pickup')}
              >
                <DeliveryOptionTitle>Store Pickup</DeliveryOptionTitle>
                <DeliveryOptionDescription>
                  Pick up from our store at {STORE_LOCATION.address}, {STORE_LOCATION.city}
                </DeliveryOptionDescription>
              </DeliveryOption>

              <DeliveryOption
                selected={deliveryMethod === 'installation'}
                onClick={() => handleDeliveryMethodChange('installation')}
              >
                <DeliveryOptionTitle>Professional Installation</DeliveryOptionTitle>
                <DeliveryOptionDescription>
                  Our team will deliver and install your system
                </DeliveryOptionDescription>
                <InstallationNote>
                  * Installation service includes delivery, professional setup, and system training
                </InstallationNote>
              </DeliveryOption>
            </DeliveryOptions>
          </DeliveryMethodSection>

          <MonitoringSection>
            <SectionTitle>Monitoring Packages</SectionTitle>
            <MonitoringDescription>
              Enhance your security system with professional monitoring services. Our monitoring packages provide 24/7 protection and emergency response capabilities, ensuring your property is protected around the clock.
            </MonitoringDescription>

            {/* Alarm Monitoring */}
            <MonitoringGroup>
              <MonitoringGroupTitle>Alarm Monitoring</MonitoringGroupTitle>
              <MonitoringOptions>
                {monitoringPackages.alarm.map(pkg => (
                  <MonitoringOption
                    key={pkg.id}
                    selected={selectedMonitoring.alarm?.id === pkg.id}
                    onClick={() => handleMonitoringChange('alarm', pkg)}
                    disabled={!hasProSeriesItems()}
                    style={{ opacity: hasProSeriesItems() ? 1 : 0.5 }}
                  >
                    <MonitoringOptionTitle>{pkg.name}</MonitoringOptionTitle>
                    <MonitoringOptionPrice>${pkg.price}/mo</MonitoringOptionPrice>
                    <MonitoringOptionDescription>
                      {!hasProSeriesItems() ? 
                        'Alarm monitoring is only available for PROSERIES alarms at this time.' :
                        pkg.description
                      }
                    </MonitoringOptionDescription>
                    {pkg.id === 'pro-alarm' && hasProSeriesItems() && (
                      <MonitoringAddons onClick={(e) => e.stopPropagation()}>
                        <AddonOption>
                          <input
                            type="checkbox"
                            checked={selectedMonitoring.lte}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleAddonChange('lte');
                            }}
                            id="lte-addon"
                          />
                          <label htmlFor="lte-addon" onClick={(e) => e.stopPropagation()}>Add LTE Backup (+$10.00/mo)</label>
                        </AddonOption>
                        {deliveryMethod === 'installation' && (
                          <AddonOption>
                            <input
                              type="checkbox"
                              checked={selectedMonitoring.alarmWarranty}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleAddonChange('alarmWarranty');
                              }}
                              id="alarm-warranty-addon"
                            />
                            <label htmlFor="alarm-warranty-addon" onClick={(e) => e.stopPropagation()}>Add Alarm Warranty (+$10.00/mo)</label>
                          </AddonOption>
                        )}
                      </MonitoringAddons>
                    )}
                  </MonitoringOption>
                ))}
              </MonitoringOptions>
            </MonitoringGroup>

            {/* Video Monitoring */}
            <MonitoringGroup>
              <MonitoringGroupTitle>Video Monitoring</MonitoringGroupTitle>
              <MonitoringOptions>
                {monitoringPackages.video.map(pkg => (
                  <MonitoringOption
                    key={pkg.id}
                    selected={selectedMonitoring.video?.id === pkg.id}
                    onClick={() => handleMonitoringChange('video', pkg)}
                  >
                    <MonitoringOptionTitle>{pkg.name}</MonitoringOptionTitle>
                    <MonitoringOptionPrice>
                      ${pkg.price}/mo{pkg.perCamera ? '/camera' : ''}
                    </MonitoringOptionPrice>
                    <MonitoringOptionDescription>{pkg.description}</MonitoringOptionDescription>
                    {pkg.id === 'pro-video' && (
                      <MonitoringAddons onClick={(e) => e.stopPropagation()}>
                        {deliveryMethod === 'installation' && (
                          <AddonOption>
                            <input
                              type="checkbox"
                              checked={selectedMonitoring.videoWarranty}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleAddonChange('videoWarranty');
                              }}
                              id="video-warranty-addon"
                            />
                            <label htmlFor="video-warranty-addon" onClick={(e) => e.stopPropagation()}>Add Video Warranty (+$10.00/mo)</label>
                          </AddonOption>
                        )}
                      </MonitoringAddons>
                    )}
                    {pkg.perCamera && getCameraCount() > 0 && (
                      <MonitoringNote>
                        Total for {getCameraCount()} camera(s): ${(pkg.price * getCameraCount()).toFixed(2)}/mo
                      </MonitoringNote>
                    )}
                  </MonitoringOption>
                ))}
              </MonitoringOptions>
            </MonitoringGroup>
          </MonitoringSection>

          {(deliveryMethod === 'shipping' || deliveryMethod === 'installation') && (
            <CustomerInfoSection>
              <SectionTitleRow>
                <SectionTitle>Contact & Shipping Information</SectionTitle>
                <AutoFillButton onClick={handleAutoFill} disabled={loading}>
                  {loading ? 'Loading...' : '📍 Auto-fill Address'}
                </AutoFillButton>
              </SectionTitleRow>
              <FormGrid>
                <FormField>
                  <Label>First Name *</Label>
                  <Input
                    type="text"
                    value={customerInfo.firstName}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Last Name *</Label>
                  <Input
                    type="text"
                    value={customerInfo.lastName}
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Email *</Label>
                  <Input
                    type="email"
                    value={customerInfo.email}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Phone</Label>
                  <Input
                    type="tel"
                    value={customerInfo.phone}
                    onChange={handlePhoneChange}
                    placeholder="(248) 555-0123"
                  />
                </FormField>
                <FormField $full>
                  <Label>Address *</Label>
                  <Input
                    type="text"
                    value={customerInfo.address}
                    onChange={(e) => handleInputChange('address', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>City *</Label>
                  <Input
                    type="text"
                    value={customerInfo.city}
                    onChange={(e) => handleInputChange('city', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>State *</Label>
                  <Select
                    value={customerInfo.state}
                    onChange={(e) => handleInputChange('state', e.target.value)}
                    required
                  >
                    <option value="">Select State</option>
                    {US_STATES.map(state => (
                      <option key={state.abbreviation} value={state.abbreviation}>
                        {state.name}
                      </option>
                    ))}
                  </Select>
                </FormField>
                <FormField>
                  <Label>ZIP Code *</Label>
                  <Input
                    type="text"
                    inputMode="numeric"
                    value={customerInfo.zipCode}
                    onChange={handleZipCodeChange}
                    required
                    maxLength="5"
                    pattern="[0-9]*"
                    autoComplete="off"
                  />

                </FormField>
              </FormGrid>
              <CalculateButton
                onClick={handleValidateAddress}
                disabled={calculatingFees || !customerInfo.zipCode || customerInfo.zipCode.length !== 5}
                required={!distance}
              >
                {calculatingFees ? 'Calculating...' :
                  deliveryMethod === 'installation' ? 'Calculate Delivery Fee' : 'Validate Address'}
              </CalculateButton>
              {shippingError && <InputError>{shippingError}</InputError>}
            </CustomerInfoSection>
          )}

          {deliveryMethod === 'pickup' && (
            <CustomerInfoSection>
              <SectionTitle>Contact Information</SectionTitle>
              <FormGrid>
                <FormField>
                  <Label>First Name *</Label>
                  <Input
                    type="text"
                    value={customerInfo.firstName}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Last Name *</Label>
                  <Input
                    type="text"
                    value={customerInfo.lastName}
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Email *</Label>
                  <Input
                    type="email"
                    value={customerInfo.email}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                    required
                  />
                </FormField>
                <FormField>
                  <Label>Phone</Label>
                  <Input
                    type="tel"
                    value={customerInfo.phone}
                    onChange={handlePhoneChange}
                    placeholder="(248) 555-0123"
                  />
                </FormField>
              </FormGrid>
            </CustomerInfoSection>
          )}

          <OrderSummary>
            <SectionTitle>Order Summary</SectionTitle>
            <ProductRecommendation location="cart" />
            {cartItems.map((item) => (
              <OrderItem key={item.id}>
                {item.imageUrl && (
                  <ItemImage src={item.imageUrl} alt={item.name} />
                )}
                <ItemDetails>
                  <ItemName>{item.name}</ItemName>
                  {item.type === 'package' && (
                    <>
                      <PackageLabel>Package</PackageLabel>
                      <SystemComponents>
                        {item.products?.map((product, index) => (
                          <ComponentItem key={`product-${index}`}>
                            • {product.name} × {product.packageQuantity || product.quantity || 1} (${((product.price || 0) * (product.packageQuantity || product.quantity || 1)).toFixed(2)})
                          </ComponentItem>
                        ))}
                      </SystemComponents>
                    </>
                  )}
                  {item.type === 'system' && (
                    <SystemComponents>
                      {item.components?.model && (
                        <ComponentItem>
                          • Virtual Guard: {item.components.model.name} (${item.components.model.price.toFixed(2)})
                        </ComponentItem>
                      )}
                      {item.components?.nvr && (
                        <ComponentItem>
                          • NVR: {item.components.nvr.name} (${item.components.nvr.price.toFixed(2)})
                        </ComponentItem>
                      )}
                      {item.components?.power && (
                        <ComponentItem>
                          • POE: {item.components.power.name} (${item.components.power.price.toFixed(2)})
                        </ComponentItem>
                      )}
                      {item.components?.alarms?.map((alarm, index) => (
                        <ComponentItem key={`alarm-${index}`}>
                          • {alarm.name} × {alarm.quantity} (${(alarm.price * alarm.quantity).toFixed(2)})
                        </ComponentItem>
                      ))}
                      {item.components?.cameras?.map((camera, index) => (
                        <ComponentItem key={`camera-${index}`}>
                          • {camera.name} × {camera.quantity} (${(camera.price * camera.quantity).toFixed(2)})
                        </ComponentItem>
                      ))}
                      {item.components?.deterrence?.map((device, index) => (
                        <ComponentItem key={`deterrence-${index}`}>
                          • {device.name} × {device.quantity} (${(device.price * device.quantity).toFixed(2)})
                        </ComponentItem>
                      ))}
                    </SystemComponents>
                  )}
                  <ItemPrice>${(item.price * item.quantity).toFixed(2)}</ItemPrice>
                  <QuantityControls>
                    <QuantityButton
                      onClick={() => handleQuantityChange(item.id, Math.max(1, item.quantity - 1))}
                      disabled={item.quantity <= 1}
                    >
                      -
                    </QuantityButton>
                    <QuantityInput
                      type="number"
                      min="1"
                      value={item.quantity}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value);
                        if (!isNaN(newValue) && newValue >= 1) {
                          handleQuantityChange(item.id, newValue);
                        }
                      }}
                    />
                    <QuantityButton
                      onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                    >
                      +
                    </QuantityButton>
                  </QuantityControls>
                </ItemDetails>
                <RemoveButton onClick={() => {
                  const updatedItems = cartItems.filter(i => i.id !== item.id);
                  if (updatedItems.length === 0) {
                    navigate('/products');
                  }
                  removeFromCart(item.id);
                }}>×</RemoveButton>
              </OrderItem>
            ))}

            <TotalSection>
              <TotalRow>
                <TotalLabel>Subtotal:</TotalLabel>
                <TotalValue>${getTotalPrice().toFixed(2)}</TotalValue>
              </TotalRow>

              {deliveryMethod === 'shipping' && (
                <TotalRow>
                  <TotalLabel>Shipping</TotalLabel>
                  <TotalValue>FREE</TotalValue>
                </TotalRow>
              )}

              {deliveryMethod === 'installation' && (
                <>
                  <TotalRow>
                    <TotalLabel>Base Installation Fee:</TotalLabel>
                    <TotalValue>
                      {calculatingFees ? (
                        <LoadingText>Calculating...</LoadingText>
                      ) : (
                        `$${(installationFee).toFixed(2)}`
                      )}
                    </TotalValue>
                  </TotalRow>
                  {distance > 50 && (
                    <TotalRow>
                      <TotalLabel>
                        Distance Fee ({distance} miles)
                      </TotalLabel>
                      <TotalValue>
                        ${Math.min(distance - 50, 150).toFixed(2)}
                      </TotalValue>
                    </TotalRow>
                  )}
                </>
              )}

              {selectedMonitoring.alarm && (
                <>
                  <TotalRow>
                    <TotalLabel>Alarm Monitoring:</TotalLabel>
                    <TotalValue>${selectedMonitoring.alarm.price.toFixed(2)}/mo</TotalValue>
                  </TotalRow>
                  {selectedMonitoring.alarmWarranty && (
                    <TotalRow>
                      <TotalLabel>Alarm Warranty:</TotalLabel>
                      <TotalValue>$10.00/mo</TotalValue>
                    </TotalRow>
                  )}
                  {selectedMonitoring.lte && (
                    <TotalRow>
                      <TotalLabel>LTE Backup:</TotalLabel>
                      <TotalValue>$10.00/mo</TotalValue>
                    </TotalRow>
                  )}
                </>
              )}

              {selectedMonitoring.video && (
                <>
                  <TotalRow>
                    <TotalLabel>
                      Video Monitoring {selectedMonitoring.video.perCamera && `(${getCameraCount()} cameras)`}:
                    </TotalLabel>
                    <TotalValue>
                      ${(selectedMonitoring.video.perCamera
                        ? selectedMonitoring.video.price * getCameraCount()
                        : selectedMonitoring.video.price).toFixed(2)}/mo
                    </TotalValue>
                  </TotalRow>
                  {selectedMonitoring.videoWarranty && (
                    <TotalRow>
                      <TotalLabel>Video Warranty:</TotalLabel>
                      <TotalValue>$10.00/mo</TotalValue>
                    </TotalRow>
                  )}
                </>
              )}

              <TotalRow $final>
                <TotalLabel>Total:</TotalLabel>
                <TotalValue>${getTotalWithFees().toFixed(2)}</TotalValue>
              </TotalRow>

              <DisclaimerMessage location="checkout" />

              <CheckoutButton
                onClick={handleCheckoutClick}
                disabled={isCheckoutDisabled().disabled}
              >
                {loading ? 'Processing...' : 'Complete Purchase'}
              </CheckoutButton>
            </TotalSection>
          </OrderSummary>
        </CheckoutContent>
      </CheckoutContainer>
      <Footer />
      <Toast $isVisible={toast.show} type={toast.type}>
        {toast.message}
      </Toast>
    </View>
  );
}

const CheckoutContainer = styled.div`
  flex: 1;
  padding: 90px 20px 40px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 80px);
  font-family: Tahoma, sans-serif;
`;

const CheckoutContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const CheckoutTitle = styled.h1`
  font-family: Tahoma, sans-serif;
  font-size: 32px;
  color: ${colors.primary.solid};
  margin-bottom: 20px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  background: #fee;
  color: #c00;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
`;

const CloseError = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  color: #c00;
  cursor: pointer;
  padding: 5px;
`;

const OrderSummary = styled.div`
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CustomerInfoSection = styled.div`
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-family: Tahoma, sans-serif;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const OrderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  position: relative;
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  background: white;
  border-radius: 8px;
  padding: 5px;
`;

const ItemDetails = styled.div`
  flex: 1;
`;

const ItemName = styled.h3`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  margin: 0 0 5px;
`;

const ItemQuantity = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0 0 5px;
`;

const ItemPrice = styled.p`
  font-size: 16px;
  color: ${colors.primary.solid};
  font-weight: 600;
  margin: 0;
`;

const TotalSection = styled.div`
  margin-top: 30px;
  border-top: 2px solid #eee;
  padding-top: 20px;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  ${props => props.$final && `
    border-top: 2px solid #eee;
    margin-top: 10px;
    padding-top: 20px;
    font-size: 20px;
    font-weight: 600;
  `}
`;

const TotalLabel = styled.span`
  font-family: Tahoma, sans-serif;
  color: #666;
`;

const TotalValue = styled.span`
  font-family: Tahoma, sans-serif;
  color: ${colors.primary.solid};
`;

const CheckoutButton = styled.button`
  width: 100%;
  padding: 16px;
  background: ${props => props.disabled ? '#ccc' : `linear-gradient(0deg, ${colors.primary.start} -14.96%, ${colors.primary.end} 100%)`};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  opacity: ${props => props.disabled ? 0.7 : 1};
  margin-top: 20px;

  &:hover {
    transform: ${props => props.disabled ? 'none' : 'scale(1.02)'};
    box-shadow: ${props => props.disabled ? 'none' : '0 4px 12px rgba(231, 28, 36, 0.2)'};
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormField = styled.div`
  grid-column: ${props => props.$full ? '1 / -1' : 'auto'};
`;

const Label = styled.label`
  font-family: Tahoma, sans-serif;
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
`;

const Input = styled.input`
  font-family: Tahoma, sans-serif;
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  background: #f8f8f8;

  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
    box-shadow: 0 0 0 2px rgba(231, 28, 36, 0.1);
    background: white;
  }
`;

const SectionTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const AutoFillButton = styled.button`
  background: none;
  border: 1px solid ${colors.primary.solid};
  color: ${colors.primary.solid};
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: ${colors.primary.solid}10;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Select = styled.select`
  font-family: Tahoma, sans-serif;
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  background: #f8f8f8;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${colors.primary.solid};
    box-shadow: 0 0 0 2px rgba(231, 28, 36, 0.1);
    background: white;
  }
`;

// Add new styled components
const DeliveryMethodSection = styled.div`
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;

const DeliveryOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const DeliveryOption = styled.div`
  border: 2px solid ${props => props.selected ? colors.primary.solid : '#eee'};
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: ${props => props.selected ? `${colors.primary.solid}10` : 'white'};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const DeliveryOptionTitle = styled.h3`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${colors.primary.solid};
`;

const DeliveryOptionDescription = styled.p`
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
`;

const InstallationNote = styled.p`
  font-size: 12px;
  color: ${colors.primary.solid};
  font-style: italic;
  margin-top: 10px;
`;

// Add styled components for quantity controls
const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
`;

const QuantityButton = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.primary.solid};
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: white;
      color: ${colors.primary.solid};
    }
  }
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${colors.primary.solid};
  background: white;
  color: ${colors.primary.solid};
  font-size: 16px;
  text-align: center;

  /* Remove default browser increment/decrement buttons */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield; /* Firefox */
`;

const InputError = styled.div`
  color: ${colors.primary.solid};
  font-size: 12px;
  margin-top: 5px;
  padding: 5px 0;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: ${colors.primary.solid};
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  
  &:hover {
    opacity: 0.8;
  }
`;

const MonitoringSection = styled.div`
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;

const MonitoringGroup = styled.div`
  margin-bottom: 30px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const MonitoringGroupTitle = styled.h3`
  font-family: Tahoma, sans-serif;
  font-size: 20px;
  color: ${colors.primary.solid};
  margin-bottom: 15px;
`;

const MonitoringOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
`;

const MonitoringOption = styled.div`
  border: 2px solid ${props => props.selected ? colors.primary.solid : '#eee'};
  border-radius: 10px;
  padding: 20px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  background: ${props => props.selected ? `${colors.primary.solid}10` : 'white'};
  opacity: ${props => props.disabled ? 0.5 : 1};

  &:hover {
    ${props => !props.disabled && `
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    `}
  }
`;

const MonitoringOptionTitle = styled.h4`
  font-family: Tahoma, sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${colors.primary.solid};
`;

const MonitoringOptionPrice = styled.p`
  font-family: Tahoma, sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
`;

const MonitoringOptionDescription = styled.p`
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
`;

const MonitoringAddons = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`;

const AddonOption = styled.div`
  font-family: Tahoma, sans-serif;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  
  label {
    font-size: 14px;
    color: #666;
  }
`;

const MonitoringNote = styled.p`
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: ${colors.primary.solid};
  margin-top: 10px;
  font-style: italic;
`;

// Add new styled components for descriptions
const MonitoringDescription = styled.p`
  font-family: Tahoma, sans-serif;
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.5;
`;

const MonitoringGroupDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin: -5px 0 20px;
  line-height: 1.4;
`;

// Add new styled component for loading text
const LoadingText = styled.span`
  font-family: Tahoma, sans-serif;
  color: ${colors.primary.solid};
  font-style: italic;
  animation: pulse 1.5s ease-in-out infinite;
  display: inline-block;
  margin-left: 5px;

  @keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
  }
`;

const MonitoringTotalNote = styled.div`
  font-family: Tahoma, sans-serif;
  margin-top: 15px;
  padding-top: 10px;
  font-size: 14px;
  color: ${colors.primary.solid};
  text-align: right;
  font-style: italic;
`;

// Add these styled components
const SystemComponents = styled.div`
  margin: 10px 0;
  font-size: 12px;
  color: #666;
  background: #f9f9f9;
  padding: 8px;
  border-radius: 6px;
`;

const ComponentItem = styled.div`
  margin: 4px 0;
  padding-left: 10px;
  font-family: 'Tahoma', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:not(:last-child) {
    border-bottom: 1px dashed #eee;
    padding-bottom: 4px;
  }
`;

const PackageLabel = styled.div`
  font-family: 'Tahoma', sans-serif;
  display: inline-block;
  padding: 2px 8px;
  background-color: ${colors.primary.solid};
  color: white;
  border-radius: 12px;
  font-size: 12px;
  margin-bottom: 5px;
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
}); 