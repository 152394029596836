import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { colors } from '../styles/SharedStyles';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';

export default function OrderSuccessPage() {
  const navigate = useNavigate();
  const { clearCart } = useCart();

  useEffect(() => {
    clearCart();
  }, []);

  return (
    <View style={styles.container}>
      <HeaderBar />
      <SuccessContainer>
        <SuccessContent>
          <SuccessIcon>✓</SuccessIcon>
          <SuccessTitle>Order Successful!</SuccessTitle>
          <SuccessMessage>
            Thank you for your purchase. We'll send you an email confirmation shortly.
          </SuccessMessage>
          <ButtonGroup>
            <Button primary onClick={() => navigate('/products')}>
              Continue Shopping
            </Button>
          </ButtonGroup>
        </SuccessContent>
      </SuccessContainer>
      <Footer />
    </View>
  );
}

const SuccessContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: #f5f5f5;
`;

const SuccessContent = styled.div`
  background: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
`;

const SuccessIcon = styled.div`
  width: 80px;
  height: 80px;
  background: ${colors.primary.solid};
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  margin: 0 auto 20px;
`;

const SuccessTitle = styled.h1`
  font-size: 32px;
  color: ${colors.primary.solid};
  margin-bottom: 20px;
`;

const SuccessMessage = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const Button = styled.button`
  padding: 15px 40px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  
  background: ${props => props.primary ? colors.primary.solid : 'white'};
  color: ${props => props.primary ? 'white' : colors.primary.solid};
  border: ${props => props.primary ? 'none' : `2px solid ${colors.primary.solid}`};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
}); 