import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, TextInput, ScrollView } from 'react-native';
import { colors } from '../../styles/SharedStyles';
import { db, storage } from '../../config/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { GradientButton } from '../GradientButton';
import { SearchBar } from '../SearchBar';
import { TabButton } from '../TabButton';
import styled from 'styled-components';

// Add styled components for image upload
const FileInputLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: ${colors.primary.solid};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;

  &:hover {
    background: ${colors.primary.dark};
  }
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

export const PackageBuilder = () => {
  const [products, setProducts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [packageDetails, setPackageDetails] = useState({
    title: '',
    description: '',
    price: '',
    features: [''],
    id: null,
    imageUrl: ''
  });
  const [selectedTab, setSelectedTab] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    fetchProducts();
    fetchPackages();
    setSelectedTab(null);
  }, []);

  const fetchPackages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'packages'));
      const packagesData = querySnapshot.docs.map(doc => ({
        docId: doc.id,
        ...doc.data()
      }));
      setPackages(packagesData);
    } catch (err) {
      console.error('Error fetching packages:', err);
      setError('Failed to fetch packages: ' + err.message);
    }
  };

  const handleQuantityChange = (productId, newValue) => {
    const value = parseInt(newValue);
    if (value >= 0) {
      if (value === 0) {
        setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
      } else {
        setSelectedProducts(selectedProducts.map(p =>
          p.id === productId ? { ...p, quantity: value } : p
        ));
      }
    }
  };

  const handleQuantityAdjust = (productId, increment) => {
    const product = selectedProducts.find(p => p.id === productId);
    const newQuantity = (product?.quantity || 0) + increment;
    if (newQuantity > 0) {
      handleQuantityChange(productId, newQuantity);
    } else {
      setSelectedProducts(selectedProducts.filter(p => p.id !== productId));
    }
  };

  const handleEdit = (pkg) => {
    setPackageDetails({
      title: pkg.title,
      description: pkg.description,
      price: pkg.price.toString(),
      features: pkg.features || [''],
      id: pkg.docId,
      imageUrl: pkg.imageUrl
    });
    setSelectedProducts(pkg.products.map(p => ({
      ...products.find(prod => prod.id === p.id),
      quantity: p.quantity
    })));
  };

  const handleDelete = async (docId) => {
    try {
      await deleteDoc(doc(db, 'packages', docId));
      await fetchPackages();
    } catch (err) {
      console.error('Error deleting package:', err);
      setError('Failed to delete package: ' + err.message);
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.sku.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsData);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products: ' + err.message);
    }
    setLoading(false);
  };

  const handleProductSelect = (product) => {
    const existingProduct = selectedProducts.find(p => p.id === product.id);
    if (existingProduct) {
      setSelectedProducts(selectedProducts.map(p => 
        p.id === product.id 
          ? { ...p, quantity: p.quantity + 1 }
          : p
      ));
    } else {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    }
  };

  const handleFeatureChange = (index, value) => {
    const newFeatures = [...packageDetails.features];
    newFeatures[index] = value;
    setPackageDetails({ ...packageDetails, features: newFeatures });
  };

  const addFeature = () => {
    setPackageDetails({
      ...packageDetails,
      features: [...packageDetails.features, '']
    });
  };

  const removeFeature = (index) => {
    const newFeatures = packageDetails.features.filter((_, i) => i !== index);
    setPackageDetails({ ...packageDetails, features: newFeatures });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    try {
      const storageRef = ref(storage, `packages/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (!packageDetails.title || !packageDetails.price) {
      setError('Title and price are required');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      let imageUrl = packageDetails.imageUrl;
      if (imageFile) {
        imageUrl = await uploadImage(imageFile);
      }

      const packageData = {
        title: packageDetails.title,
        description: packageDetails.description || '',
        price: parseFloat(packageDetails.price),
        products: selectedProducts.map(p => ({
          id: p.id,
          name: p.name,
          imageUrl: p.imageUrl || '',
          quantity: p.quantity || 1
        })),
        features: packageDetails.features.filter(f => f.trim() !== ''),
        imageUrl: imageUrl || ''
      };

      if (packageDetails.id) {
        await updateDoc(doc(db, 'packages', packageDetails.id), packageData);
        setError('Package updated successfully!');
      } else {
        await addDoc(collection(db, 'packages'), packageData);
        setError('Package created successfully!');
      }

      await fetchPackages();
      setPackageDetails({
        title: '',
        description: '',
        price: '',
        features: [''],
        id: null,
        imageUrl: ''
      });
      setSelectedProducts([]);
      setImageFile(null);
      setImagePreview(null);
    } catch (err) {
      console.error('Error saving package:', err);
      setError('Failed to save package: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <ScrollView style={styles.container}>
      <View>
        <Text style={styles.title}>Package Builder</Text>
      </View>
      
      {error && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
          <GradientButton
            onPress={() => setError(null)}
            text="Dismiss"
            variant="secondary"
            style={styles.dismissButton}
          />
        </View>
      )}

      <View style={styles.form}>
        <View>
          <TextInput
            style={styles.input}
            placeholder="Package Title"
            value={packageDetails.title}
            onChangeText={(text) => setPackageDetails({...packageDetails, title: text})}
          />
        </View>

        <View style={styles.imageUploadContainer}>
          <View>
            <Text style={styles.sectionTitle}>Package Image</Text>
          </View>
          {imagePreview || packageDetails.imageUrl ? (
            <View style={styles.imagePreviewContainer}>
              <img
                src={imagePreview || packageDetails.imageUrl}
                alt="Package preview"
                style={styles.imagePreview}
              />
              <GradientButton
                onPress={() => {
                  setImageFile(null);
                  setImagePreview(null);
                  setPackageDetails({...packageDetails, imageUrl: ''});
                }}
                text="Remove Image"
                variant="secondary"
                style={styles.removeImageButton}
              />
            </View>
          ) : (
            <View style={styles.imageUploadBox}>
              <HiddenFileInput
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
              />
              <FileInputLabel onClick={() => fileInputRef.current?.click()}>
                Choose Image
              </FileInputLabel>
            </View>
          )}
        </View>

        <TextInput
          style={[styles.input, styles.textArea]}
          placeholder="Package Description"
          value={packageDetails.description}
          onChangeText={(text) => setPackageDetails({...packageDetails, description: text})}
          multiline
        />

        <TextInput
          style={styles.input}
          placeholder="Package Price"
          value={packageDetails.price}
          onChangeText={(text) => setPackageDetails({...packageDetails, price: text})}
          keyboardType="numeric"
        />

        <View style={styles.featuresContainer}>
          <Text style={styles.sectionTitle}>Package Features</Text>
          {packageDetails.features.map((feature, index) => (
            <View key={index} style={styles.featureRow}>
              <TextInput
                style={[styles.input, styles.featureInput]}
                placeholder="Feature description"
                value={feature}
                onChangeText={(text) => handleFeatureChange(index, text)}
              />
              <GradientButton
                onPress={() => removeFeature(index)}
                text="Remove"
                variant="secondary"
                style={styles.removeButton}
              />
            </View>
          ))}
          <GradientButton
            onPress={addFeature}
            text="Add Feature"
            variant="secondary"
            style={styles.addButton}
          />
        </View>

        <View style={styles.productsSection}>
          <Text style={styles.sectionTitle}>Add Products to Package</Text>
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search products by name or SKU..."
          />
          <ScrollView style={styles.productsList}>
            {filteredProducts.map((product) => (
              <View key={product.id} style={styles.productItem}>
                {product.imageUrl && (
                  <img 
                    src={product.imageUrl} 
                    alt={product.name}
                    style={styles.productImage}
                  />
                )}
                <View style={styles.productInfo}>
                  <Text style={styles.productName}>{product.name}</Text>
                  <Text style={styles.productPrice}>${product.price}</Text>
                  <Text style={styles.productSku}>SKU: {product.sku}</Text>
                </View>
                <GradientButton
                  onPress={() => handleProductSelect(product)}
                  text="Add"
                  variant="secondary"
                  style={styles.addButton}
                />
              </View>
            ))}
          </ScrollView>
        </View>

        <View style={styles.selectedProducts}>
          <Text style={styles.sectionTitle}>Selected Products</Text>
          {selectedProducts.map((product) => (
            <View key={product.id} style={styles.selectedProductItem}>
              {product.imageUrl && (
                <img 
                  src={product.imageUrl} 
                  alt={product.name}
                  style={styles.productImage}
                />
              )}
              <Text style={styles.productName}>{product.name}</Text>
              <View style={styles.quantityContainer}>
                <GradientButton
                  onPress={() => handleQuantityAdjust(product.id, -1)}
                  text="-"
                  variant="secondary"
                  style={styles.quantityButton}
                />
                <TextInput
                  style={styles.quantityInput}
                  value={String(product.quantity)}
                  onChangeText={(text) => handleQuantityChange(product.id, text)}
                  keyboardType="numeric"
                />
                <GradientButton
                  onPress={() => handleQuantityAdjust(product.id, 1)}
                  text="+"
                  variant="secondary"
                  style={styles.quantityButton}
                />
              </View>
            </View>
          ))}
        </View>

        <View style={styles.buttonContainer}>
          <GradientButton
            onPress={handleSubmit}
            text={packageDetails.id ? "Update Package" : "Create Package"}
            disabled={loading}
            style={styles.submitButton}
          />
        </View>
      </View>

      <View style={styles.packagesList}>
        <Text style={styles.sectionTitle}>Existing Packages</Text>
        {packages.map((pkg) => (
          <View key={pkg.docId} style={styles.packageItem}>
            <View style={styles.packageInfo}>
              <Text style={styles.packageTitle}>{pkg.title || 'Untitled Package'}</Text>
              <Text style={styles.packagePrice}>${pkg.price || '0'}</Text>
              <View style={styles.packageProducts}>
                {pkg.products?.map((product) => (
                  <View key={product.id} style={styles.packageProductThumb}>
                    <img 
                      src={product.imageUrl || 'https://via.placeholder.com/50'} 
                      alt={product.name}
                      style={styles.thumbImage}
                    />
                    <Text style={styles.thumbQuantity}>x{product.quantity || 1}</Text>
                  </View>
                ))}
              </View>
              {pkg.features && pkg.features.length > 0 && (
                <View style={styles.packageFeatures}>
                  {pkg.features.map((feature, index) => (
                    <Text key={index} style={styles.featureText}>• {feature}</Text>
                  ))}
                </View>
              )}
            </View>
            <View style={styles.packageActions}>
              <GradientButton
                onPress={() => handleEdit(pkg)}
                text="Edit"
                variant="secondary"
                style={styles.actionButton}
              />
              <GradientButton
                onPress={() => handleDelete(pkg.docId)}
                text="Delete"
                variant="secondary"
                style={[styles.actionButton, styles.deleteButton]}
              />
            </View>
          </View>
        ))}
      </View>

      {loading && (
        <View style={styles.loadingOverlay}>
          <Text style={styles.loadingText}>Processing...</Text>
        </View>
      )}


      {selectedTab === 'products' && <ProductsTabContent />}
      {selectedTab === 'features' && <FeaturesTabContent />}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary.solid,
  },
  form: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  input: {
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    fontSize: 14,
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  featuresContainer: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: colors.primary.solid,
  },
  featureRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  featureInput: {
    flex: 1,
    marginRight: 10,
    marginBottom: 0,
  },
  productsSection: {
    marginBottom: 20,
  },
  productsList: {
    maxHeight: 200,
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 10,
  },
  productItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray,
  },
  productInfo: {
    flex: 1,
  },
  productName: {
    fontSize: 16,
    fontWeight: '500',
  },
  productPrice: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 'bold',
  },
  selectedProducts: {
    marginBottom: 20,
  },
  selectedProductItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: colors.lightGray,
    borderRadius: 5,
    marginBottom: 10,
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  quantityLabel: {
    marginRight: 10,
    fontSize: 14,
  },
  quantityInput: {
    width: 60,
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 5,
    padding: 5,
    textAlign: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: 20,
  },
  submitButton: {
    minWidth: 180,
  },
  addButton: {
    minWidth: 80,
    height: 36,
    fontSize: 14,
  },
  removeButton: {
    minWidth: 100,
    height: 36,
    fontSize: 14,
  },
  errorContainer: {
    backgroundColor: props => props.error?.includes('successfully') 
      ? 'rgba(0, 255, 0, 0.1)' 
      : 'rgba(255, 0, 0, 0.1)',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorText: {
    color: props => props.error?.includes('successfully') 
      ? colors.success 
      : colors.error,
    flex: 1,
  },
  dismissButton: {
    minWidth: 80,
    marginLeft: 10,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: colors.primary.solid,
    fontSize: 16,
    fontWeight: '600',
  },
  productImage: {
    width: 60,
    height: 60,
    objectFit: 'cover',
    borderRadius: 5,
    marginRight: 15,
    backgroundColor: 'white',
    padding: 5,
  },
  quantityButton: {
    width: 30,
    height: 30,
    padding: 0,
    minWidth: 'auto',
    fontSize: 16,
  },
  packagesList: {
    backgroundColor: colors.white,
    padding: 20,
    borderRadius: 10,
    marginTop: 20,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  packageItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 10,
  },
  packageInfo: {
    flex: 1,
  },
  packageTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5,
  },
  packagePrice: {
    fontSize: 16,
    color: colors.primary.solid,
    marginBottom: 10,
  },
  packageProducts: {
    flexDirection: 'row',
    gap: 10,
    flexWrap: 'wrap',
  },
  packageProductThumb: {
    position: 'relative',
    width: 50,
    height: 50,
  },
  thumbImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 5,
    backgroundColor: 'white',
    padding: 2,
  },
  thumbQuantity: {
    position: 'absolute',
    right: -5,
    bottom: -5,
    backgroundColor: colors.primary.solid,
    color: 'white',
    borderRadius: 10,
    padding: '2px 5px',
    fontSize: 12,
  },
  packageActions: {
    flexDirection: 'row',
    gap: 10,
  },
  actionButton: {
    minWidth: 80,
    height: 36,
  },
  deleteButton: {
    backgroundColor: colors.error,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '600',
  },
  packageFeatures: {
    marginTop: 10,
    paddingTop: 10,
    borderTopWidth: 1,
    borderTopColor: colors.gray.light,
  },
  featureText: {
    fontSize: 14,
    color: colors.gray.dark,
    marginBottom: 5,
  },
  imageUploadContainer: {
    marginBottom: 20,
    width: '100%',
  },
  imagePreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 10,
  },
  imagePreview: {
    width: 200,
    height: 200,
    objectFit: 'contain',
    borderRadius: 8,
    backgroundColor: '#f5f5f5',
    padding: 10,
    border: '1px solid #ddd',
  },
  imageUploadBox: {
    width: '100%',
    minHeight: 100,
    borderWidth: 2,
    borderColor: colors.gray,
    borderStyle: 'dashed',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginTop: 10,
    backgroundColor: '#f9f9f9',
  },
  removeImageButton: {
    minWidth: 120,
    marginTop: 10,
  },
}); 