import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CartProvider } from './src/context/CartContext';
import { SplashProvider } from './src/context/SplashContext';
import { AuthProvider } from './src/context/AuthContext';
import { ProtectedRoute } from './src/components/ProtectedRoute';
import SplashScreen from './src/components/SplashScreen';
import PackagesPage from './src/pages/PackagesPage';
import { BuildPage } from './src/pages/BuildPage';
import ServicesPage from './src/pages/ServicesPage';
import { ContactPage } from './src/pages/ContactPage';
import { LandingPage } from './src/pages/LandingPage';
import { CartWidget } from './src/components/CartWidget';
import { SocialMediaTab } from './src/components/SocialMediaTab';
import { AnimatedGradientBackground } from './src/components/AnimatedGradientBackground';
import { AdminPage } from './src/pages/AdminPage';
import { AdminLoginPage } from './src/pages/AdminLoginPage';
import SupportPage from './src/pages/SupportPage';
import ProductsPage from './src/pages/ProductsPage';
import CheckoutPage from './src/pages/CheckoutPage';
import OrderSuccessPage from './src/pages/OrderSuccessPage';
import AnnouncementDisplay from './src/components/AnnouncementDisplay';
import DisclaimerPage from './src/pages/policies/DisclaimerPage';
import LinkingPolicyPage from './src/pages/policies/LinkingPolicyPage';
import PrivacyPolicyPage from './src/pages/policies/PrivacyPolicyPage';
import TestimonialsDisclosurePage from './src/pages/policies/TestimonialsDisclosurePage';
import TermsOfUsePage from './src/pages/policies/TermsOfUsePage';
import { BlogPage } from './src/pages/BlogPage';
import { BlogPostPage } from './src/pages/BlogPostPage';
import { initializeDisclaimers } from './src/utils/initializeDisclaimers';
import { enableDisclaimers } from './src/utils/testDisclaimers';

const AppContent = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isCheckoutRoute = location.pathname === '/checkout' || location.pathname === '/order-success';

  useEffect(() => {
    // Initialize disclaimers when the app starts
    const setupDisclaimers = async () => {
      await initializeDisclaimers();
      await enableDisclaimers();
    };
    
    setupDisclaimers();
  }, []);

  return (
    <AnimatedGradientBackground>
      <View style={styles.container}>
        {!isAdminRoute && <AnnouncementDisplay style={{ zIndex: 900 }} />}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/packages" element={<PackagesPage />} />
          <Route path="/build" element={<BuildPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/admin/login" element={<AdminLoginPage />} />
          <Route path="/admin/*" element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          } />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/order-success" element={<OrderSuccessPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPostPage />} />
          <Route path="/policies/disclaimer" element={<DisclaimerPage />} />
          <Route path="/policies/linking-policy" element={<LinkingPolicyPage />} />
          <Route path="/policies/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/policies/testimonials-disclosure" element={<TestimonialsDisclosurePage />} />
          <Route path="/policies/terms-of-use" element={<TermsOfUsePage />} />
        </Routes>
        {!isAdminRoute && !isCheckoutRoute && (
          <>
            <CartWidget />
            <SocialMediaTab />
          </>
        )}
      </View>
    </AnimatedGradientBackground>
  );
};

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <SplashProvider>
          <CartProvider>
            <SplashScreen>
              <AppContent />
            </SplashScreen>
          </CartProvider>
        </SplashProvider>
      </AuthProvider>
    </Router>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
  },
}); 