const isDevelopment = process.env.NODE_ENV === 'development';
const EMULATOR_HOST = 'http://127.0.0.1:5001/security-central-protection/us-central1';
const PRODUCTION_HOST = 'https://sendcontactform-xtvsxjb7uq-uc.a.run.app';

export const endpoints = {
  sendContactForm: isDevelopment 
    ? `${EMULATOR_HOST}/sendContactForm`
    : PRODUCTION_HOST,
  createCheckoutSession: isDevelopment
    ? `${EMULATOR_HOST}/createCheckoutSession`
    : 'https://createcheckoutsession-xtvsxjb7uq-uc.a.run.app',
  stripeWebhook: isDevelopment
    ? `${EMULATOR_HOST}/stripeWebhook`
    : 'https://stripewebhook-xtvsxjb7uq-uc.a.run.app'
}; 