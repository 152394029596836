import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCstpLxMtN32FLKG6BqCExhp8NQGqAAmHw",
  authDomain: "security-central-protection.firebaseapp.com",
  projectId: "security-central-protection",
  storageBucket: "security-central-protection.firebasestorage.app",
  messagingSenderId: "1045621525803",
  appId: "1:1045621525803:web:4a3d8ef6dc55b0fd38896e",
  measurementId: "G-0X4F4PF9BH"
};

// Initialize Firebase
let app;
let db;
let analytics;
let functions;
let storage;

try {
  app = initializeApp(firebaseConfig);
  db = getFirestore(app);
  analytics = getAnalytics(app);
  functions = getFunctions(app);
  storage = getStorage(app);

  // Enable offline persistence
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a time.
      console.log('Persistence failed: Multiple tabs open');
    } else if (err.code === 'unimplemented') {
      // The current browser doesn't support persistence
      console.log('Persistence not supported by browser');
    }
  });
} catch (error) {
  console.error('Error initializing Firebase:', error);
}

export { app, analytics, functions, db, storage }; 