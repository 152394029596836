import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, Button } from 'react-native';
import { db } from '../../config/firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { colors, typography } from '../../styles/SharedStyles';
import { GradientButton } from '../../components/GradientButton';

export const FulfillmentManager = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'orders'));
        const ordersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Fetched orders:', ordersData);
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const updateOrderStatus = async (orderId, status) => {
    try {
      await updateDoc(doc(db, 'orders', orderId), { status });
      setOrders(orders.map(order => order.id === orderId ? { ...order, status } : order));
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const renderOrderItem = ({ item }) => (
    <View style={styles.orderCard}>
      <Text style={styles.orderTitle}>Order ID: {item.id}</Text>
      <View style={styles.orderContent}>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Status:</Text>
          <Text style={styles.orderValue}>{item.status}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Total:</Text>
          <Text style={styles.orderValue}>${item.total ? item.total.toFixed(2) : 'N/A'}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Delivery Method:</Text>
          <Text style={styles.orderValue}>{item.deliveryMethod || 'N/A'}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Installation Fee:</Text>
          <Text style={styles.orderValue}>${item.installationFee ? item.installationFee.toFixed(2) : 'N/A'}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Shipping Cost:</Text>
          <Text style={styles.orderValue}>${item.shippingCost ? item.shippingCost.toFixed(2) : 'N/A'}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Created At:</Text>
          <Text style={styles.orderValue}>{item.createdAt ? new Date(item.createdAt).toLocaleString() : 'N/A'}</Text>
        </View>
        <View style={styles.orderDetails}>
          <Text style={styles.orderLabel}>Customer Info:</Text>
          <Text style={styles.orderValue}>{item.customerInfo ? `${item.customerInfo.name}, ${item.customerInfo.email}` : 'N/A'}</Text>
        </View>
        <Text style={styles.orderItemsTitle}>Items:</Text>
        <View style={styles.itemsContainer}>
          {item.items ? item.items.map((orderItem, index) => (
            <Text key={index} style={styles.orderItemText}>- {orderItem.name} x{orderItem.quantity}</Text>
          )) : <Text style={styles.orderItemText}>No items available</Text>}
        </View>
      </View>
      <View style={styles.buttonGroup}>
        {item.status === 'pending' && (
          <GradientButton onPress={() => updateOrderStatus(item.id, 'fulfilled')} text="Mark as Fulfilled" variant="primary" style={styles.actionButton} />
        )}
        {item.status === 'fulfilled' && (
          <GradientButton onPress={() => updateOrderStatus(item.id, 'archived')} text="Archive" variant="secondary" style={styles.actionButton} />
        )}
      </View>
    </View>
  );

  const pendingOrders = orders.filter(order => order.status === 'pending');
  const fulfilledOrders = orders.filter(order => order.status === 'fulfilled');
  const archivedOrders = orders.filter(order => order.status === 'archived');

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Order Fulfillment</Text>
      <View style={styles.columnsContainer}>
        <View style={styles.column}>
          <Text style={styles.columnTitle}>Pending Orders ({pendingOrders.length})</Text>
          <FlatList
            data={pendingOrders}
            keyExtractor={item => item.id}
            renderItem={renderOrderItem}
            contentContainerStyle={styles.listContent}
          />
        </View>
        
        <View style={styles.column}>
          <Text style={styles.columnTitle}>Fulfilled Orders ({fulfilledOrders.length})</Text>
          <FlatList
            data={fulfilledOrders}
            keyExtractor={item => item.id}
            renderItem={renderOrderItem}
            contentContainerStyle={styles.listContent}
          />
        </View>

        <View style={styles.column}>
          <Text style={styles.columnTitle}>Archived Orders ({archivedOrders.length})</Text>
          <FlatList
            data={archivedOrders}
            keyExtractor={item => item.id}
            renderItem={renderOrderItem}
            contentContainerStyle={styles.listContent}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: colors.background || '#f5f5f5',
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.primary || '#e71c24',
    textAlign: 'center',
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 15,
    height: 'calc(100vh - 150px)',
  },
  column: {
    flex: 1,
    width: 'calc(33.333% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  columnTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    color: colors.primary || '#e71c24',
    textAlign: 'center',
    borderBottom: '2px solid #f0f0f0',
    paddingBottom: 10,
  },
  listContent: {
    paddingBottom: 20,
  },
  orderCard: {
    padding: 15,
    marginBottom: 15,
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    border: '1px solid #eee',
    display: 'flex',
    flexDirection: 'column',
  },
  orderTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.primary || '#e71c24',
    borderBottom: '1px solid #f0f0f0',
    paddingBottom: 8,
  },
  orderContent: {
    flex: 1,
  },
  orderDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  orderLabel: {
    fontWeight: '600',
    color: '#666',
    fontSize: 14,
  },
  orderValue: {
    color: '#333',
    fontSize: 14,
    maxWidth: '60%',
    textAlign: 'right',
  },
  orderItemsTitle: {
    fontWeight: '600',
    marginTop: 10,
    marginBottom: 5,
    color: '#333',
    fontSize: 14,
  },
  itemsContainer: {
    maxHeight: 100,
    overflowY: 'auto',
  },
  orderItemText: {
    marginLeft: 10,
    color: '#666',
    fontSize: 13,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 15,
    borderTop: '1px solid #f0f0f0',
    paddingTop: 10,
  },
  actionButton: {
    minWidth: 150,
    height: 36,
  },
}); 