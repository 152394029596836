import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TextInput, TouchableOpacity, Image } from 'react-native';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { FadeInView } from '../components/FadeInView';
import { colors } from '../styles/SharedStyles';
import styled from 'styled-components';
import { endpoints } from '../config/endpoints';
import { usePageTitle } from '../hooks/usePageTitle';

// Add styled components for the form inputs to match original styling
const StyledInput = styled.input`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  background-color: #fff;
  width: 100%;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 480px) {
    padding: 10px;
    margin-bottom: 12px;
    font-size: 14px;
    border-radius: 6px;
  }
`;

const StyledTextArea = styled.textarea`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  height: 120px;
  background-color: #fff;
  width: 100%;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 480px) {
    padding: 10px;
    margin-bottom: 12px;
    font-size: 14px;
    height: 90px;
    border-radius: 6px;
  }
`;

const SubmitButton = styled.button`
  padding: 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: linear-gradient(0deg, #e71c24 -14.96%, #912528 100%);
  border: none;
  cursor: pointer;
  
  @media (max-width: 480px) {
    padding: 12px;
    border-radius: 6px;
  }
`;

const ButtonText = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

// Update the ContactForm component to use the styled components
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (!formData.name || !formData.email || !formData.message) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    setError(null);
    
    try {
      console.log('Sending to:', endpoints.sendContactForm);
      const response = await fetch(endpoints.sendContactForm, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': window.location.origin
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log('Response:', data);

      if (!response.ok) {
        throw new Error(data.error || data.details || 'Failed to send message');
      }

      setSuccess(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (err) {
      console.error('Error sending message:', err);
      setError(err.message || 'Failed to send message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: '95%' }}>
      {error && (
        <ErrorMessage>
          {error}
          <CloseError onClick={() => setError(null)}>×</CloseError>
        </ErrorMessage>
      )}
      
      {success && (
        <SuccessMessage>
          Thank you for your message! We'll get back to you shortly.
          <CloseSuccess onClick={() => setSuccess(false)}>×</CloseSuccess>
        </SuccessMessage>
      )}

      <StyledInput
        placeholder="Full Name *"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      />
      <StyledInput
        placeholder="Email *"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        type="email"
      />
      <StyledInput
        placeholder="Phone Number"
        value={formData.phone}
        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        type="tel"
      />
      <StyledTextArea
        placeholder="Your Message *"
        value={formData.message}
        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
        rows={4}
      />
      <SubmitButton 
        onClick={handleSubmit}
        disabled={loading}
      >
        <ButtonText>
          {loading ? 'Sending...' : 'Send Message'}
        </ButtonText>
      </SubmitButton>
    </div>
  );
};

const ErrorMessage = styled.div`
  background: #fee;
  color: #c00;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 768px) {
    padding: 12px;
    font-size: 14px;
    margin-bottom: 15px;
  }
`;

const SuccessMessage = styled.div`
  background: #efe;
  color: #060;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 768px) {
    padding: 12px;
    font-size: 14px;
    margin-bottom: 15px;
  }
`;

const CloseError = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  color: #c00;
  cursor: pointer;
  padding: 5px;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 768px) {
    font-size: 18px;
    padding: 4px;
  }
`;

const CloseSuccess = styled(CloseError)`
  color: #060;
`;

// Add styled components for the layout
const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 80px;
  
  @media (min-width: 992px) {
    flex-direction: row;
    height: calc(100vh - 80px); /* Subtract header height */
  }
  
  @media (max-width: 991px) {
    padding-top: 60px;
  }
  
  @media (max-width: 480px) {
    padding-top: 40px;
    min-height: auto;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow: auto;
  
  @media (max-width: 768px) {
    padding: 30px;
  }
  
  @media (max-width: 480px) {
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 25px;
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end; /* Position content at the bottom */
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url(${require('../assets/images/contact_us.jpg')});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 991px) {
    min-height: 500px;
  }
  
  @media (max-width: 480px) {
    min-height: 450px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-position: center center;
  }
`;

const RightContent = styled.div`
  width: 100%;
  padding: 0 40px 40px; /* Add padding to bottom */
  
  @media (max-width: 768px) {
    padding: 0 30px 30px;
  }
  
  @media (max-width: 480px) {
    padding: 0 15px 20px;
  }
`;

const BottomContent = styled.div`
  padding: 40px;
  border-radius: 20px;
  @media (max-width: 768px) {
    padding: 30px;
  }
  
  @media (max-width: 480px) {
    padding: 15px;
    border-radius: 12px;
  }
`;

const MainInfo = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 20px;
  width: 100%;
  
  @media (max-width: 480px) {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
`;

const MainTitle = styled.h2`
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 10px;
  letter-spacing: 1px;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 768px) {
    font-size: 36px;
  }
  
  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
  }
`;

const Subtitle = styled.p`
  font-size: 22px;
  margin-bottom: 0;
  line-height: 32px;
  width: 100%;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
  
  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

const ContactGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  
  @media (max-width: 480px) {
    gap: 12px;
    flex-direction: column;
  }
`;

const InfoSection = styled.div`
  flex: 1;
  min-width: 150px;
  
  @media (max-width: 480px) {
    margin-bottom: 8px;
    min-width: auto;
  }
`;

const InfoTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const InfoText = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
  opacity: 0.9;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 3px;
  }
`;

const FormWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  
  @media (max-width: 768px) {
  max-width: 80vw;
    margin-top: 30px;
  }
  
  @media (max-width: 480px) {
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const FormTitle = styled.h1`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 30px;
  color: ${colors.primary};
  text-align: center;
  font-family: "Tahoma", sans-serif;
  
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  @media (max-width: 480px) {
    font-size: 22px;
    margin-bottom: 15px;
  }
`;

// Create a container styled component
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: "Tahoma", sans-serif;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
`;

const MainContent = styled.div`
  flex: 1;
  width: 100%;
`;

export const ContactPage = () => {
  usePageTitle('Contact Us');
  
  return (
    <Container>
      <HeaderBar />
      <Content>
        <MainContent>
          <SplitContainer>
            {/* Left Section - Form */}
            <LeftSection>
              <FadeInView duration={800} direction="left" delay={200}>
                <FormWrapper>
                  <FormTitle>Send us a Message</FormTitle>
                  <ContactForm />
                </FormWrapper>
              </FadeInView>
            </LeftSection>

            {/* Right Section - Info with Background */}
            <RightSection>
              <RightContent>
                <BottomContent>
                  {/* Main content */}
                  <FadeInView duration={800} direction="right" delay={400}>
                    <MainInfo>
                      <MainTitle>
                        Need help building your security solution?
                      </MainTitle>
                      <Subtitle>
                        Reach out and our expert team will get back to you within 24 hours!
                      </Subtitle>
                    </MainInfo>
                  </FadeInView>

                  {/* Contact Grid */}
                  <FadeInView duration={800} direction="up" delay={600}>
                    <ContactGrid>
                      <InfoSection>
                        <InfoTitle>Address</InfoTitle>
                        <InfoText>12821 Capital Street</InfoText>
                        <InfoText>Oak Park, MI 48237</InfoText>
                      </InfoSection>
                      
                      <InfoSection>
                        <InfoTitle>Phone</InfoTitle>
                        <InfoText>248.543.0000</InfoText>
                      </InfoSection>
                      
                      <InfoSection>
                        <InfoTitle>Email</InfoTitle>
                        <InfoText>info@scp911.com</InfoText>
                      </InfoSection>
                    </ContactGrid>
                  </FadeInView>
                </BottomContent>
              </RightContent>
            </RightSection>
          </SplitContainer>
        </MainContent>
      </Content>
      <Footer />
    </Container>
  );
}; 