import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin, FaYoutube, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';

const FooterWrapper = styled.footer`
  background-color: #7A7A7A;
  width: 100%;
  color: rgb(255, 255, 255);
  letter-spacing: 0.5px;
  padding: 60px 20px 20px;
  font-family: 'Tahoma', sans-serif;
  
  @media (max-width: 991px) {
    padding: 40px 20px 20px;
  }
`;

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  
  @media (max-width: 1200px) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    gap: 20px;
  }
  
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 2px;
    background-color: #e71c24;
    
    @media (max-width: 768px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const LogoImage = styled.img`
  width: 180px;
  height: auto;
  object-fit: contain;
`;

const CompanyDescription = styled.p`
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
  opacity: 0.9;
  max-width: 300px;
  
  @media (max-width: 768px) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 5px;
  
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialLink = styled.a`
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-3px);
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  &.facebook:hover {
    color: #1877F2; /* Facebook blue */
  }
  
  &.twitter:hover {
    color: #000000; /* Twitter/X black */
  }
  
  &.linkedin:hover {
    color: #0A66C2; /* LinkedIn blue */
  }
  
  &.youtube:hover {
    color: #FF0000; /* YouTube red */
  }
`;

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledLink = styled(Link)`
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 14px;
  opacity: 0.9;
  transition: all 0.2s ease;
  display: inline-block;
  position: relative;
  padding-left: 15px;
  
  &:before {
    content: '›';
    position: absolute;
    left: 0;
    transition: transform 0.2s ease;
  }
  
  &:hover {
    color: #e71c24;
    opacity: 1;
    transform: translateX(3px);
    
    &:before {
      transform: translateX(3px);
    }
  }
  
  @media (max-width: 768px) {
    padding-left: 0;
    
    &:before {
      display: none;
    }
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ContactIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
`;

const ContactText = styled.div`
  font-size: 14px;
  opacity: 0.9;
`;

const ContactLink = styled.a`
  color: white;
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: #e71c24;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 40px 0 20px;
`;

const BottomBar = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Copyright = styled.div`
  font-size: 13px;
  opacity: 0.8;
`;

const Credits = styled.div`
  font-size: 13px;
  opacity: 0.8;
  
  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: #e71c24;
    }
  }
  
  strong {
    font-weight: 600;
  }
`;

// Navigation items from the header
const navigationItems = [
  {text: "Home", path: "/"},
  { text: "Our Services", path: "/services" },
  { text: "Build a System", path: "/build" },
  { text: "All Products", path: "/products" },
  { text: "Shop Packages", path: "/packages" },
  { text: "Support", path: "/support" },
  { text: "Contact Us", path: "/contact" }
];

// Policy links
const policyLinks = [
  { text: "Disclaimer", path: "/policies/disclaimer" },
  { text: "Linking Policy", path: "/policies/linking-policy" },
  { text: "Privacy Policy", path: "/policies/privacy-policy" },
  { text: "Testimonials Disclosure", path: "/policies/testimonials-disclosure" },
  { text: "Terms of Use", path: "/policies/terms-of-use" }
];

export const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterSection>
          <LogoContainer>
            <LogoImage src={require('../assets/logo.png')} alt="SCP911 Logo" />
          </LogoContainer>
          <CompanyDescription>
            Since 1984, Security Central Protection has delivered comprehensive security solutions tailored for residential, commercial, and industrial needs.
          </CompanyDescription>
          <SocialLinks>
            <SocialLink href="https://www.facebook.com/people/Security-Central-Protection/100087748939726/" target="_blank" rel="noopener noreferrer" className="facebook">
              <FaFacebook size={16} />
            </SocialLink>
            <SocialLink href="https://twitter.com/scp_911" target="_blank" rel="noopener noreferrer" className="twitter">
              <FaXTwitter size={16} />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/security-central-protection-services/" target="_blank" rel="noopener noreferrer" className="linkedin">
              <FaLinkedin size={16} />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UC9WujQWYB_24w7X4MZxLnRw/videos" target="_blank" rel="noopener noreferrer" className="youtube">
              <FaYoutube size={18} />
            </SocialLink>
          </SocialLinks>
        </FooterSection>
        
        <FooterSection>
          <SectionTitle>Quick Links</SectionTitle>
          <LinkGroup>
            {navigationItems.map((link, index) => (
              <StyledLink key={`nav-${index}`} to={link.path}>
                {link.text}
              </StyledLink>
            ))}
          </LinkGroup>
        </FooterSection>
        
        <FooterSection>
          <SectionTitle>Contact Us</SectionTitle>
          <ContactItem>
            <ContactIcon>
              <FaMapMarkerAlt size={16} />
            </ContactIcon>
            <ContactText>
              12821 Capital Street Oak Park, MI 48237
            </ContactText>
          </ContactItem>
          
          <ContactItem>
            <ContactIcon>
              <FaPhone size={16} />
            </ContactIcon>
            <ContactText>
              <ContactLink href="tel:2485430000">248.543.0000</ContactLink>
            </ContactText>
          </ContactItem>
          
          <ContactItem>
            <ContactIcon>
              <MdEmail size={16} />
            </ContactIcon>
            <ContactText>
              <ContactLink href="mailto:info@scp911.com">info@scp911.com</ContactLink>
            </ContactText>
          </ContactItem>
        </FooterSection>
        
        <FooterSection>
          <SectionTitle>Legal</SectionTitle>
          <LinkGroup>
            {policyLinks.map((link, index) => (
              <StyledLink key={`policy-${index}`} to={link.path}>
                {link.text}
              </StyledLink>
            ))}
          </LinkGroup>
        </FooterSection>
      </FooterContainer>
      
      <Divider />
      
      <BottomBar>
        <Copyright>
          © 2025 Security Central Protection — All Rights Reserved
        </Copyright>
        <Credits>
          <a href="https://dsignable.com" target="_blank" rel="noopener noreferrer">
            This site was created by <strong>Team Dsignable</strong>
          </a>
        </Credits>
      </BottomBar>
    </FooterWrapper>
  );
}; 